import React from 'react';
import { SmallCheckbox } from '../../../../styles/shared-styled-components';
import {
  depositAmountOptions,
  dollarInputType,
  dropdownInputType,
  flatRateType,
  percentInputType,
} from '../../../../utils/constants';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptCheckboxContainer,
  PromptCheckboxContentContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptSubtitleText,
  PromptTitleText,
} from '../../shared-training-components';

const Deposits = ({ policy, onChange, loading }) => {
  const depositPolicy = policy?.deposit || {};
  const requiredForLeads = depositPolicy?.requiredForLeads || '';
  const requiredForClients = depositPolicy?.requiredForClients || '';
  const requiredForMembers = depositPolicy?.requiredForMembers || '';
  const type = depositPolicy?.type || '';
  const amount = depositPolicy?.amount || '';
  const formUrl = depositPolicy?.formUrl || '';
  const bufferHoursRequired = depositPolicy?.bufferHoursRequired || '';

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptTitleText>
          Select who your deposit policy should apply to
        </PromptTitleText>
        <PromptCheckboxContainer>
          <PromptCheckboxContentContainer>
            <PromptSubtitleText>Applies to leads</PromptSubtitleText>
            <SmallCheckbox
              checked={requiredForLeads}
              onClick={(e) => {
                onChange({
                  ...depositPolicy,
                  requiredForLeads: e.target.checked,
                });
              }}
            />
          </PromptCheckboxContentContainer>
          <PromptCheckboxContentContainer>
            <PromptSubtitleText>Applies to clients</PromptSubtitleText>
            <SmallCheckbox
              checked={requiredForClients}
              onChange={(e) => {
                onChange({
                  ...depositPolicy,
                  requiredForClients: e.target.checked,
                });
              }}
            />
          </PromptCheckboxContentContainer>
          <PromptCheckboxContentContainer>
            <PromptSubtitleText>Applies to members</PromptSubtitleText>
            <SmallCheckbox
              checked={requiredForMembers}
              onChange={(e) => {
                onChange({
                  ...depositPolicy,
                  requiredForMembers: e.target.checked,
                });
              }}
            />
          </PromptCheckboxContentContainer>
        </PromptCheckboxContainer>
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Deposit type</PromptTitleText>
        <PromptInput
          value={type}
          type={dropdownInputType}
          options={depositAmountOptions}
          onChange={(e) => {
            onChange({
              ...depositPolicy,
              type: e.target.value,
            });
          }}
        />
      </PromptContainer>
      {(type === flatRateType || type === percentInputType) && (
        <PromptContainer>
          <PromptTitleText>Amount required</PromptTitleText>
          <PromptInput
            value={amount}
            type={dollarInputType}
            onChange={(e) => {
              onChange({
                ...depositPolicy,
                amount: safeParseInt(e.target.value),
              });
            }}
          />
        </PromptContainer>
      )}
      <PromptContainer>
        <PromptTitleText>Form URL</PromptTitleText>
        <PromptInput
          value={formUrl}
          onChange={(e) => {
            onChange({
              ...depositPolicy,
              formUrl: e.target.value,
            });
          }}
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Notice hours required for booking</PromptTitleText>
        <PromptInput
          value={bufferHoursRequired}
          onChange={(e) => {
            onChange({
              ...depositPolicy,
              bufferHoursRequired: safeParseInt(e.target.value),
            });
          }}
        />
      </PromptContainer>
    </PromptsSection>
  );
};

export default Deposits;

import styled, { css } from 'styled-components';
import {
  CancelIcon,
  CenterFlexStyle,
  ColumnCenteredDiv,
  EssGapStyle,
  ExtraSmallIconSize,
  SmallGapStyle,
  StartAlignedExtraSmallLinkText,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';

export const DateRangeFieldContainer = styled(ColumnCenteredDiv)``;

export const DateRangeFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${TextInputWidthStyle}
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${EssGapStyle}
`;

export const AttachFileText = styled(StartAlignedExtraSmallLinkText)`
  padding-left: 10px;
  width: fit-content;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.color.disabledText};
    `}
`;

export const AttachedFilesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  ${SmallGapStyle}
`;

export const AttachedFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${EssGapStyle}
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: 10px;
  padding: 2px 10px;
`;

export const RemoveAttachedFileIcon = styled(CancelIcon)`
  ${ExtraSmallIconSize}
  ${CenterFlexStyle}
  color: ${({ theme }) => theme.color.lightError} !important;
`;

export const FilesDescriptionContainer = styled.div`
  margin-top: 18px;
`;

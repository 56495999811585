import React, { useContext, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { useMyLocations } from '../../../../api/hooks/enterprise';
import BarChart from '../../../../components/Analytics/BarChart/BarChart';
import DoubleBarChart from '../../../../components/Analytics/BarChart/DoubleBarChart';
import LineGraph from '../../../../components/Analytics/LineGraph';
import PieChart from '../../../../components/Analytics/PieChart';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AddLocationModal from '../../../../components/Modals/AddLocationModal';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import RangePopperMenuCalendar from '../../../../components/Schedule/Calendar/RangePopperMenuCalendar';
import Snackbar from '../../../../components/Snackbar';
import Paths from '../../../../Paths';
import {
  AddIcon,
  AgentAvatarContainer,
  AnalystAvatar,
  CenteredDiv,
  ColumnCenteredDiv,
  ContentContainer,
  ExtraSmallPrimaryAddIcon,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MenuItem,
  MenuItemCheckmark,
  PageContainer,
  PageTitleText,
  SmallMenuItemText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedMediumDarkTinyText,
  StartAlignedSMText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  justCompletedOnboardingCookieKey,
  justCompletedOnboardingSnackbarMessage,
} from '../../../../utils/auth';
import {
  cancelledStatusKey,
  completeStatusKey,
  percentDataType,
  scheduledStatusKey,
} from '../../../../utils/constants';
import { getFormattedDateString } from '../../../../utils/date';
import {
  formatNumber,
  getFormattedPercentageString,
} from '../../../../utils/numbers';
import { getUserBusiness } from '../../../../utils/user';
import {
  Container,
  NoLocationsCreatedContainer,
  QueryFieldText,
  QueryPanel,
  QueryPanelContainer,
  SectionDiv,
  SectionTitleContainer,
  TopPanel,
  TopPanelSection,
  TopSection,
} from './styled';

const topPanelFields = [
  {
    key: 'revenue',
    label: 'Revenue Driven',
    prefix: '$',
    path: Paths.revenue,
  },
  {
    key: 'appointments',
    label: 'Appointments Booked',
  },
  {
    key: 'talkTime',
    label: 'Talk Time',
    suffix: ' mins',
  },
  {
    key: 'humanCostDiverted',
    label: 'Human Cost Diverted',
    prefix: '$',
  },
];

const hardcodedData = {
  revenue: 12397,
  appointments: 83,
  talkTime: 2611,
  humanCostDiverted: 2400,
};
const inHardcodedMode = true;

// Overview
const appointmentCategoryData = [
  {
    label: 'Massages',
    value: 53,
  },
  {
    label: 'Facials',
    value: 18,
  },
  {
    label: 'Stretch',
    value: 29,
  },
];
const total = 52 + 22 + 9;
const appointmentStatusData = [
  {
    label: 'Scheduled',
    status: scheduledStatusKey,
    value: (22 / total).toFixed(1) * 100,
  },
  {
    label: 'Complete',
    status: completeStatusKey,
    value: (52 / total).toFixed(1) * 100,
  },
  {
    label: 'Cancel',
    status: cancelledStatusKey,
    value: (9 / total).toFixed(1) * 100,
  },
];
const campaignData = [
  {
    key: 'a',
    label: 'September Stretch',
    value: 50,
  },
  {
    key: 'b',
    label: 'Membership Upsell',
    value: 30,
  },
  {
    key: 'c',
    label: 'Facials Promo',
    value: 30,
  },
];

// Phone calls
const phoneCallResolutionData = [
  {
    label: 'Handled',
    value: 55,
  },
  {
    label: 'Callback',
    value: 18,
  },
  {
    label: 'Transferred',
    value: 27,
  },
];
const callTopicsData = [
  {
    range: 'Sep 23-29',
    Create: 3,
    Reschedule: 2,
    Cancel: 3,
  },
  {
    range: 'Sep 30-Oct 6',
    Create: 9,
    Reschedule: 2,
    Cancel: 5,
  },
  {
    range: 'Oct 7-13',
    Create: 12,
    Reschedule: 3,
    Cancel: 2,
  },
  {
    range: 'Oct 14-21',
    Create: 11,
    Reschedule: 3,
    Cancel: 5,
  },
  {
    range: 'Oct 21-Now',
    Create: 4,
    Reschedule: 1,
    Cancel: 1,
  },
];
const callContactTypes = [
  {
    label: 'Clients',
    value: 68,
  },
  {
    label: 'Leads',
    value: 12,
  },
  {
    label: 'Unknown',
    value: 20,
  },
];

// Campaigns
const campaignLeadConversionData = [
  {
    time: 'Sep 23-29',
    'September Stretch': 20,
    'Membership Upsell': 35,
    'Facials Promo': 40,
  },
  {
    time: 'Sep 30-Oct 6',
    'September Stretch': 24,
    'Membership Upsell': 33,
    'Facials Promo': 48,
  },
  {
    time: 'Oct 7-13',
    'Membership Upsell': 37,
    'Facials Promo': 34,
  },
  {
    time: 'Oct 14-21',
    'Membership Upsell': 31,
    'Facials Promo': 46,
  },
  {
    time: 'Oct 21-Now',
    'Membership Upsell': 40,
    'Facials Promo': 29,
  },
];
const campaignRatesData = [
  {
    time: 'Sep 23-29',
    Response: 8,
    Interception: 1,
    Unsubscribe: 3,
  },
  {
    time: 'Sep 30-Oct 6',
    Response: 9,
    Interception: 1.5,
    Unsubscribe: 2.7,
  },
  {
    time: 'Oct 7-13',
    Response: 7,
    Interception: 0.8,
    Unsubscribe: 4,
  },
  {
    time: 'Oct 14-21',
    Response: 11,
    Interception: 2.5,
    Unsubscribe: 5,
  },
  {
    time: 'Oct 21-Now',
    Response: 7,
    Interception: 1,
    Unsubscribe: 3,
  },
];
const campaignSourceData = [
  {
    key: 'a',
    label: 'Meta',
    value: 62,
  },
  {
    key: 'a',
    label: 'Google',
    value: 21,
  },
  {
    key: 'a',
    label: 'Web',
    value: 17,
  },
];

const HeadOffice = () => {
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded, cookies, removeCookie } =
    useContext(BaseContext);

  const business = getUserBusiness(user);
  const businessName = business?.name;

  const {
    locations,
    refetch: refetchLocations,
    loading: locationsLoading,
  } = useMyLocations();
  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({ shallow: true });

  const allLocationIds = [];
  const locationOptions = (locations || []).map((l) => {
    allLocationIds.push(l.id);
    return { key: l.id, label: l.nickname };
  });
  const [filteredLocations, setFilteredLocations] = useState(
    locationOptions.map((c) => c.key),
  );
  const [addedLocation, setAddedLocation] = useState(false);

  const allCampaignIds = [];
  const campaignsToUse = inHardcodedMode ? campaignData : campaigns || [];
  const campaignOptions = campaignsToUse.map((c) => {
    allCampaignIds.push(c.id);
    return { key: c.id || c.key, label: c.name || c.label };
  });
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaignOptions.map((c) => c.key),
  );

  useEffect(() => {
    if (!filteredLocations?.length || addedLocation) {
      setFilteredLocations(locationOptions.map((c) => c.key));
      setAddedLocation(false);
    }
  }, [addedLocation, locationOptions]);

  useEffect(() => {
    if (!filteredCampaigns?.length) {
      setFilteredCampaigns(campaignOptions.map((c) => c.key));
    }
  }, [campaignOptions]);

  const [menuField, setMenuField] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpen = Boolean(menuAnchorEl);

  const locationsMenuOpen = menuOpen && menuField === 'locations';
  const campaignsMenuOpen = menuOpen && menuField === 'campaigns';
  const datesMenuOpen = menuOpen && menuField === 'dates';

  const menuOptions =
    menuField === 'locations'
      ? locationOptions
      : menuField === 'campaigns'
      ? campaignOptions
      : [];
  const filteredOptions =
    menuField === 'locations'
      ? filteredLocations
      : menuField === 'campaigns'
      ? filteredCampaigns
      : [];

  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();

  const startLabel = startDateRange
    ? getFormattedDateString(startDateRange, false)
    : 'Start';
  const endLabel = endDateRange
    ? getFormattedDateString(endDateRange, false)
    : 'Present';
  const dateLabel =
    !startDateRange && !endDateRange
      ? `All-Time`
      : `${startLabel} - ${endLabel}`;

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);

  const onChangeFilter = (id, add) => {
    if (menuField === 'locations') {
      if (add) {
        setFilteredLocations([...filteredLocations, id]);
      } else {
        setFilteredLocations([
          ...filteredLocations.filter((key) => key !== id),
        ]);
      }
    } else if (menuField === 'campaigns') {
      if (add) {
        setFilteredCampaigns([...filteredCampaigns, id]);
      } else {
        setFilteredCampaigns([
          ...filteredCampaigns.filter((key) => key !== id),
        ]);
      }
    }
  };

  const onUpdateStart = (updatedValue) => {
    const updatedStartDate = new Date(updatedValue).toISOString();
    const endDate = endDateRange ? new Date(endDateRange).toISOString() : null;
    if (endDate && updatedStartDate > endDate) {
      const updatedEndDate = new Date(updatedStartDate);
      updatedEndDate.setDate(updatedEndDate.getDate() + 7);
      setEndDateRange(updatedEndDate.toISOString());
    }
    setStartDateRange(updatedValue);
  };

  const onUpdateEnd = (updatedValue) => {
    const updatedEndDate = new Date(updatedValue).toISOString();
    const startDate = startDateRange
      ? new Date(startDateRange).toISOString()
      : null;
    if (startDate && updatedEndDate < startDate) {
      const updatedStartDate = new Date(updatedEndDate);
      updatedStartDate.setDate(updatedStartDate.getDate() - 7);
      setStartDateRange(updatedStartDate.toISOString());
    }
    setEndDateRange(updatedValue);
  };

  const justOnboardedCookie = cookies[justCompletedOnboardingCookieKey];
  const [showConfetti, setShowConfetti] = useState(false);

  const stopConfetti = () => {
    setShowConfetti(false);
    removeCookie(justCompletedOnboardingCookieKey);
  };

  useEffect(() => {
    async function stopConfettiOnTimer() {
      setTimeout(stopConfetti, 3000);
    }
    if (justOnboardedCookie) {
      setShowConfetti(true);
      setSnackbarMessage(justCompletedOnboardingSnackbarMessage);
      stopConfettiOnTimer();
    }
  }, [justOnboardedCookie]);

  if (locationsLoading || campaignsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    locations && (
      <>
        <ReactConfetti
          size={8}
          shape='circle'
          numberOfPieces={showConfetti ? 300 : 0}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv bottomMargin={10}>
              <PageTitleText>{businessName} Dashboard</PageTitleText>
            </ColumnCenteredDiv>
            <Container>
              {locationsLoading ? (
                <LoadingIndicator />
              ) : locations?.length ? (
                <>
                  <TopSection>
                    <TopPanelSection>
                      {topPanelFields.map((p) => (
                        <TopPanel
                          clickable={true}
                          onClick={() => {
                            if (p.path) {
                              navigate(p.path);
                            }
                          }}
                        >
                          <StartAlignedMediumDarkLargeTinyText>
                            {p.label}
                          </StartAlignedMediumDarkLargeTinyText>
                          <StartAlignedSMText smallLineHeight>
                            {p.prefix || ''}
                            {p.type === percentDataType
                              ? getFormattedPercentageString(
                                  inHardcodedMode ? hardcodedData[p.key] : '0',
                                )
                              : formatNumber(
                                  inHardcodedMode ? hardcodedData[p.key] : '0',
                                )}
                            {p.suffix || ''}
                          </StartAlignedSMText>
                        </TopPanel>
                      ))}
                    </TopPanelSection>
                    <QueryPanelContainer>
                      <QueryPanel>
                        <StartAlignedFlexDiv bottomMargin={10}>
                          <AgentAvatarContainer analyst>
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Lucas can help find what you're looking for
                                </TooltipTitleText>
                              }
                            >
                              <AnalystAvatar />
                            </Tooltip>
                          </AgentAvatarContainer>
                          <StartAlignedMediumDarkTinyText smallLineHeight>
                            Let's explore your data
                          </StartAlignedMediumDarkTinyText>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={locationsMenuOpen}
                            onClick={(e) => {
                              setMenuField('locations');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {filteredLocations?.length === locations?.length
                              ? 'All Locations'
                              : `Locations`}{' '}
                            ({filteredLocations?.length})
                          </QueryFieldText>
                          <Tooltip
                            title={
                              <TooltipTitleText>Add location</TooltipTitleText>
                            }
                          >
                            <ExtraSmallPrimaryAddIcon
                              topMargin={-4}
                              onClick={() => setAddLocationModalOpen(true)}
                            />
                          </Tooltip>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={campaignsMenuOpen}
                            onClick={(e) => {
                              setMenuField('campaigns');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {filteredCampaigns?.length ===
                            campaignsToUse?.length
                              ? 'All Campaigns'
                              : `Campaigns`}{' '}
                            ({filteredCampaigns?.length})
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={datesMenuOpen}
                            onClick={(e) => {
                              setMenuField('dates');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {dateLabel}{' '}
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                      </QueryPanel>
                    </QueryPanelContainer>
                    <PopperMenu
                      open={menuOpen && !datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                      variant='offset'
                    >
                      {menuOptions.map((o) => {
                        const { key, label } = o;
                        const selected = !!filteredOptions.find(
                          (filteredValue) => filteredValue === key,
                        );
                        return (
                          <MenuItem
                            disableRipple
                            onClick={() => {
                              if (!(selected && filteredOptions.length === 1)) {
                                console.log('key', key);
                                console.log('selected', selected);
                                onChangeFilter(key, !selected);
                              }
                            }}
                          >
                            <SmallMenuItemText>{label}</SmallMenuItemText>
                            {selected && <MenuItemCheckmark />}
                          </MenuItem>
                        );
                      })}
                    </PopperMenu>
                    <RangePopperMenuCalendar
                      start={startDateRange}
                      end={endDateRange}
                      onChangeStart={onUpdateStart}
                      onChangeEnd={onUpdateEnd}
                      onReset={() => {
                        setStartDateRange();
                        setEndDateRange();
                      }}
                      open={datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                    />
                  </TopSection>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Appointments
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <CenteredDiv>
                      <PieChart
                        data={inHardcodedMode ? appointmentCategoryData : []}
                        size='medium'
                        title={'Categories'}
                      />
                    </CenteredDiv>
                    <PieChart
                      data={inHardcodedMode ? appointmentStatusData : []}
                      size='medium'
                      title={'Status'}
                    />
                    <CenteredDiv>
                      <BarChart yAxisLabel={'Total Bookings'} />
                    </CenteredDiv>
                  </SectionDiv>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Phone Calls
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <PieChart
                      data={inHardcodedMode ? phoneCallResolutionData : []}
                      size='medium'
                      title={'Resolution'}
                      ternary
                    />
                    <CenteredDiv>
                      <DoubleBarChart
                        data={callTopicsData}
                        yAxisLabel='Tasks Handled'
                        maxY={20}
                      />
                    </CenteredDiv>
                    <PieChart
                      data={inHardcodedMode ? callContactTypes : []}
                      size='medium'
                      title={'Contact Types'}
                      alt
                    />
                  </SectionDiv>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Campaigns
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <CenteredDiv>
                      <LineGraph
                        data={campaignLeadConversionData}
                        yAxisLabel='Lead Conversion %'
                      />
                    </CenteredDiv>
                    <CenteredDiv>
                      <LineGraph
                        data={campaignRatesData}
                        yAxisLabel='Interaction Rates'
                        maxY={20}
                      />
                    </CenteredDiv>
                    <PieChart
                      data={inHardcodedMode ? campaignSourceData : []}
                      size='medium'
                      title={'Source'}
                      secondary
                    />
                  </SectionDiv>
                </>
              ) : (
                <NoLocationsCreatedContainer>
                  <MediumDarkMediumText>
                    Add a location to begin tracking metrics
                  </MediumDarkMediumText>
                  <MediumPrimaryButton
                    onClick={() => setAddLocationModalOpen(true)}
                  >
                    <AddIcon /> Add location
                  </MediumPrimaryButton>
                </NoLocationsCreatedContainer>
              )}
            </Container>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
        <AddLocationModal
          isOpen={addLocationModalOpen}
          onClose={() => setAddLocationModalOpen(false)}
          onAdd={async (sentInviteToOwner) => {
            await refetchLocations();
            const snackbarText = `Added location${
              sentInviteToOwner ? ' and sent invite to location admin' : ''
            }`;
            setSnackbarMessage(snackbarText);
            setAddedLocation(true);
          }}
        />
      </>
    )
  );
};

export default HeadOffice;

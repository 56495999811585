import { useApolloClient, useMutation } from '@apollo/client';
import { matchIsValidTel } from 'mui-tel-input';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { EDIT_USER_PERSONAL_INFO } from '../../../api/mutations/users';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import Input from '../../../components/Form/Input';
import SplitInput from '../../../components/Form/SplitInput/SplitInput';
import { getObjectFromArrayOfKeyLabelObjects } from '../../../utils/array';
import { updateMyUserInCache } from '../../../utils/cache';
import { phoneInputType, userGoals } from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import {
  checkIfIsValidEmail,
  getIdOfFirstEmptyField,
} from '../../../utils/input';
import { stripAllSpacesFromString } from '../../../utils/string';
import { scrollElementIntoView } from '../../../utils/view';

const nameId = 'name';
const emailId = 'email';
const emailErrorText = 'Please enter a valid email address';
const phoneErrorText = 'Please enter a valid phone number';
const userGoalsObject = getObjectFromArrayOfKeyLabelObjects(userGoals);

const Personal = () => {
  const { cache } = useApolloClient();
  const { user, userLoading } = useContext(BaseContext);
  const navigate = useNavigate();

  const [
    editUserPersonalInfoMutation,
    { loading: editUserPersonalInfoLoading },
  ] = useMutation(EDIT_USER_PERSONAL_INFO);

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phone, setPhone] = useState(user?.phone);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false);

  const editUserPersonalInfo = () => {
    if (
      firstName === user.firstName &&
      user.lastName === lastName &&
      user.phone === phone &&
      user.email === email
    ) {
      navigate(Paths.businessOnboarding);
      return;
    }

    editUserPersonalInfoMutation({
      variables: {
        firstName,
        lastName,
        email,
        phone,
      },
      onCompleted: async (data) => {
        await updateMyUserInCache(data.editUserPersonalInfo, cache);
        navigate(Paths.businessOnboarding);
      },
    });
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhone(user.phone);
    }
  }, [user]);

  const onClickContinue = () => {
    const formattedEmail = stripAllSpacesFromString(email);
    const isValidEmail = checkIfIsValidEmail(formattedEmail);

    const isValidPhone = matchIsValidTel(phone || '');

    if (!continueEnabled) {
      const formattedInputsWithParsedSplitInput = [
        {
          props: inputs[0].props.splitInputs[0],
        },
        {
          props: inputs[0].props.splitInputs[1],
        },
        inputs[1],
        inputs[2],
      ];
      const idWithError = getIdOfFirstEmptyField(
        formattedInputsWithParsedSplitInput,
      );
      scrollElementIntoView(document, idWithError);
      setSubmittedDataWithErrors(true);

      if (email && !isValidEmail) {
        setEmailErrorMessage(emailErrorText);
      }

      if (phone && !isValidPhone) {
        setPhoneErrorMessage(phoneErrorText);
      }

      return;
    }

    if (!isValidEmail) {
      scrollElementIntoView(document, emailId);
      setEmailErrorMessage(emailErrorText);

      return;
    }

    if (!isValidPhone) {
      scrollElementIntoView(document, emailId);
      setPhoneErrorMessage(phoneErrorText);

      return;
    }

    editUserPersonalInfo();
  };

  const inputs = [
    <SplitInput
      title="What's your name?"
      id={nameId}
      splitInputs={[
        {
          id: nameId,
          value: firstName,
          onChange: (e) => setFirstName(e.target.value),
          label: 'First name',
          containsError: valueIsEmpty(firstName) && submittedDataWithErrors,
        },
        {
          id: nameId,
          value: lastName,
          onChange: (e) => setLastName(e.target.value),
          label: 'Last name',
          containsError: valueIsEmpty(lastName) && submittedDataWithErrors,
        },
      ]}
      useSmallGap={true}
    />,
    <Input
      id='phone'
      title="What's your phone number?"
      value={phone}
      onChange={(phoneNumber) => {
        setPhone(phoneNumber);
        if (phoneErrorMessage) {
          setPhoneErrorMessage();
        }
      }}
      label='Phone number'
      type={phoneInputType}
      containsError={
        phoneErrorMessage || (valueIsEmpty(phone) && submittedDataWithErrors)
      }
      errorMessage={phoneErrorMessage}
      useSmallGap={true}
    />,
    <Input
      id={emailId}
      title="What's your email?"
      value={email}
      onChange={(e) => {
        setEmail(e.target.value);
        if (emailErrorMessage) {
          setEmailErrorMessage();
        }
      }}
      label='Email address'
      containsError={
        emailErrorMessage || (valueIsEmpty(email) && submittedDataWithErrors)
      }
      errorMessage={emailErrorMessage}
      useSmallGap={true}
    />,
  ];

  const continueEnabled =
    !valueIsEmpty(firstName) &&
    !valueIsEmpty(lastName) &&
    !valueIsEmpty(email) &&
    !valueIsEmpty(phone);

  const loading = userLoading || editUserPersonalInfoLoading;

  return (
    user && (
      <FormPage
        title='Your Info'
        currentStep={1}
        totalNumSteps={2}
        isLoading={loading}
        inputs={inputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
        backEnabled={false}
      />
    )
  );
};

export default Personal;

import { formatKeyToLabel } from './string';

export const booleans = [true, false];
export const booleanLabelMap = {
  [true]: 'Yes',
  [false]: 'No',
};
export const booleanOptions = [
  { key: true, label: 'Yes' },
  { key: false, label: 'No' },
];

export const homepagePixelDistance = 80;
export const homepageMobilePixelDistance = 40;
export const homepagePercentageWidth = 70;
export const homepageMobileSideMargin = 30;
export const homepageNavbarCollapseWidth = 1350;

// Primitive filters
export const individualKey = 'individual';
export const allKey = 'all';
export const quantitites = [individualKey, allKey];
export const pastKey = 'past';
export const upcomingKey = 'upcoming';
export const timeStages = [pastKey, upcomingKey, allKey];
export const nowKey = 'now';
export const resyncKey = 'resync';
export const resyncLabel = 'Re-sync from scratch';
export const startKey = 'start';
export const endKey = 'end';

export const femaleKey = 'female';
export const maleKey = 'male';
export const nonBinaryGenderKey = 'non_binary';
export const undisclosedGenderKey = 'undisclosed';
export const genders = [femaleKey, maleKey, nonBinaryGenderKey];
export const genderOptions = [
  { key: femaleKey, label: 'Female' },
  { key: maleKey, label: 'Male' },
  { key: nonBinaryGenderKey, label: 'Non-Binary' },
  { key: undisclosedGenderKey, label: 'Undisclosed' },
];

export const eitherKey = 'either';
export const flexibleBooleans = [eitherKey, true, false];
export const flexibleBooleanLabelMap = {
  [eitherKey]: 'Either',
  [true]: 'Yes',
  [false]: 'No',
};

export const services = [
  {
    key: 'botox',
    label: 'Botox and other alternatives',
  },
  {
    key: 'facial_fillers',
    label: 'Facial Fillers',
  },
  {
    key: 'facials',
    label: 'Facials including Hydrafacial and Retinol',
  },
  {
    key: 'facial_treatments',
    label:
      'Facial Treatments including Laser, Intense Pulsed Light (IPL), Sanding, Chemical Peels, Microneedling, Radio-Frequency Thermal Energy, and Plasma Injection',
  },
  {
    key: 'body_contouring',
    label:
      'Body Contouring including High Intensity Focused Electro Magnetic Energy, Radio-Frequency Thermal Energy, KYBELLA Synthetic Deoxycholic Acid, Cool Sculpt',
  },
  {
    key: 'hair_removal',
    label:
      'Hair Removal including Laser, Intense Pulsed Light (IPL), regular spa glue and tape',
  },
  {
    key: 'hair_restoration',
    label:
      'Hair Restoration including Plasma, Keravive Light Suction and Exfoliation',
  },
  {
    key: 'iv',
    label: 'IV',
  },
  {
    key: 'other_wellness',
    label: 'Wellness',
  },
];

export const clinicSoftware = [
  {
    key: 'boulevard',
    label: 'Boulevard',
  },
  {
    key: 'daysmart',
    label: 'DaySmart',
  },
  {
    key: 'fresha',
    label: 'Fresha',
  },
  {
    key: 'growth_99',
    label: 'Growth99',
  },
  {
    key: 'mango_mint',
    label: 'Mangomint',
  },
  {
    key: 'mind_body',
    label: 'MindBody',
  },
  {
    key: 'pabau',
    label: 'Pabau',
  },
  {
    key: 'phorest',
    label: 'Phorest',
  },
  {
    key: 'prospyr',
    label: 'Prospyr',
  },
  {
    key: 'quickbooks',
    label: 'Quickbooks',
  },
  {
    key: 'vagaro',
    label: 'Vagaro',
  },
  {
    key: 'zenoti',
    label: 'Zenoti',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const asapTimeframeKey = 'asap';
export const timeframes = [
  {
    key: asapTimeframeKey,
    label: "I'm ready to begin the process now",
    value: 1,
  },
  {
    key: '0_6months',
    label: 'In the next 6 months',
    value: 2,
  },
  {
    key: '6_12months',
    label: 'In 6-12 months from now',
    value: 3,
  },
  {
    key: '1_2years',
    label: 'In the next 1-2 years',
    value: 4,
  },
  {
    key: 'not_interested',
    label: 'Not interested in that just yet',
    value: 5,
  },
];

export const transitionPeriods = [
  {
    key: '0_6months',
    label: '0-6 Months',
    value: 1,
  },
  {
    key: '6-12months',
    label: '6-12 Months',
    value: 1,
  },
  {
    key: '1-2years',
    label: '1-2 Years',
    value: 2,
  },
  {
    key: '2-3years',
    label: '2-3 Years',
    value: 3,
  },
  {
    key: '3-5years',
    label: '3-5 Years',
    value: 4,
  },
];

export const referralSourceKey = 'referral';
export const leadSources = [
  {
    key: referralSourceKey,
    label: 'I was referred here',
  },
  {
    key: 'google_search',
    label: 'Google Search',
  },
  {
    key: 'facebook_ad',
    label: 'Facebook Ad',
  },
  {
    key: 'google_ad',
    label: 'Google Ad',
  },
  {
    key: 'linkedin_ad',
    label: 'Linkedin Ad',
  },
  {
    key: 'instagram_ad',
    label: 'Instagram Ad',
  },
];

export const websiteTypeKey = 'website';
export const instagramTypeKey = 'instagram';
export const facebookTypeKey = 'facebook';
export const yelpTypeKey = 'yelp';
export const googleTypeKey = 'google';
export const appleTypeKey = 'apple';
export const bookingPageTypeKey = 'booking_page';
export const linkTypeMap = {
  [websiteTypeKey]: 'Website',
  [instagramTypeKey]: 'Instagram page',
  [bookingPageTypeKey]: 'Booking page',
  [googleTypeKey]: 'Google business page',
  [appleTypeKey]: 'Apple business page',
  [yelpTypeKey]: 'Yelp page',
  [facebookTypeKey]: 'Facebook page',
};

export const internalLeadSources = [
  {
    key: referralSourceKey,
    label: 'Personal Referrer',
  },
  {
    key: 'bizbuysell',
    label: 'BizBuySell',
  },
  {
    key: 'searchfunder',
    label: 'Search Funder',
  },
  {
    key: 'linkedin_outreach',
    label: 'Linkedin',
  },
  {
    key: 'loopnet',
    label: 'Loopnet',
  },
  {
    key: 'nursepreneur',
    label: 'Nursepreneur',
  },
];

export const valueGoalKey = 'value';
export const sellGoalKey = 'sell';
export const buyGoalKey = 'buy';
export const growGoalKey = 'grow';
export const understandGoalKey = 'understand';
export const userGoals = [
  {
    key: understandGoalKey,
    label: 'Better understand my business metrics',
  },
  {
    key: growGoalKey,
    label: 'Grow my business',
  },
  {
    key: sellGoalKey,
    label: 'Explore selling my business',
  },
];

export const buyerTypes = [
  {
    key: 'clinic_owner',
    label: 'Clinic owner',
  },
  {
    key: 'nurse_or_physician',
    label: 'Nurse, Physician, or Esthetician',
  },
  {
    key: 'pe',
    label: 'Private Equity',
  },
  {
    key: 'platform',
    label: 'Platform',
  },
  {
    key: 'search_funder',
    label: 'Search funder',
  },
  {
    key: 'search_fund_investor',
    label: 'Search fund investor',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const integerInputType = 'integer';
export const phoneInputType = 'phone';
export const locationInputType = 'location';
export const floatInputType = 'float';
export const percentInputType = 'percent';
export const dollarInputType = 'dollar';
export const textInputType = 'text';
export const dateInputType = 'date';
export const urlInputType = 'url';
export const arrayInputType = 'array';
export const dropdownInputType = 'dropdown';
export const numericInputTypes = [
  integerInputType,
  floatInputType,
  percentInputType,
  dollarInputType,
];

export const flatRateType = 'flat_rate';

export const discountOptions = [
  {
    key: dollarInputType,
    label: 'Dollar amount discount',
  },
  { key: flatRateType, label: 'Flat rate' },
  {
    key: percentInputType,
    label: 'Percentage discount',
  },
];
export const holdKey = 'hold';
export const depositAmountOptions = [
  {
    key: holdKey,
    label: 'No charge, just keeping card on file',
  },
  { key: flatRateType, label: 'Fixed price' },
  {
    key: percentInputType,
    label: 'Percentage of service',
  },
];

export const variablePriceIntegerKey = -1;
export const freePriceIntegerKey = 0;
export const positivePriceIntegerKey = 1;

// Session types
export const fixedPriceSettingKey = 'fixed_price';
export const variablePriceSettingKey = 'variable_price';
export const minPriceSettingKey = 'min_price';
export const maxPriceSettingKey = 'max_price';
export const priceSettings = [
  fixedPriceSettingKey,
  variablePriceSettingKey,
  minPriceSettingKey,
  maxPriceSettingKey,
];
export const priceSettingsLabelMap = [
  {
    key: fixedPriceSettingKey,
    label: 'Fixed price',
  },
  {
    key: variablePriceSettingKey,
    label: 'Variable price',
  },
  {
    key: minPriceSettingKey,
    label: 'Minimum variable price',
  },
  {
    key: maxPriceSettingKey,
    label: 'Maximum variable price',
  },
];

export const descendingSortDirection = 'desc';
export const ascendingSortDirection = 'asc';

export const upcomingStatusKey = 'upcoming';
export const inProgressStatusKey = 'in_progress';
export const incompleteStatusKey = 'incomplete';
export const completeStatusKey = 'complete';
export const readyToPublishStatusKey = 'ready_to_publish';
export const underReviewStatusKey = 'under_review';
export const uninitializedStatusKey = 'uninitialized';
export const upToDateStatusKey = 'up_to_date';

export const getTaskStatusChipText = (status) => {
  switch (status) {
    case incompleteStatusKey:
      return 'Incomplete';
    case completeStatusKey:
      return 'Complete';
    case readyToPublishStatusKey:
      return 'Ready to Publish';
    case underReviewStatusKey:
      return 'Under Review';
    default:
      return 'Incomplete';
  }
};

export const listingFlowKey = 'listing';

export const graphqlTypenameFieldKey = '__typename';

export const balanceSheetFileType = 'balance_sheet';
export const incomeStatementFileType = 'income_statement';

export const mindBodyKey = 'mind_body';
export const zenotiKey = 'zenoti';
export const meevoKey = 'meevo';
export const ghlKey = 'ghl';

export const quickbooksKey = 'quickbooks';
export const googleAdsKey = 'google_ads';
export const facebookAdsKey = 'facebook_ads';
export const liveiqSourceKey = 'liveiq';
export const integrations = [quickbooksKey, mindBodyKey];
export const appNameLabelMap = {
  [quickbooksKey]: 'Quickbooks',
  [mindBodyKey]: 'Mindbody',
  [ghlKey]: 'HighLevel',
  [googleAdsKey]: 'Google Ads',
  [facebookAdsKey]: 'Facebook Ads',
  [liveiqSourceKey]: 'LiveIQ',
  [zenotiKey]: 'Zenoti',
  [meevoKey]: 'Meevo',
};

// Sale types
export const saleTypeKeySuffix = '_sale_type';
export const serviceSaleType = 'service';
export const productSaleType = 'product';
export const giftCardSaleType = 'gift_card';
export const tipSaleType = 'tip';
export const membershipSaleType = 'membership';
export const saleTypeLabels = {
  [`${serviceSaleType}${saleTypeKeySuffix}`]: 'Services',
  [`${productSaleType}${saleTypeKeySuffix}`]: 'Products',
  [`${giftCardSaleType}${saleTypeKeySuffix}`]: 'Gift Cards',
  [`${tipSaleType}${saleTypeKeySuffix}`]: 'Tips',
  [`${membershipSaleType}${saleTypeKeySuffix}`]: 'Memberships',
};

export const unclassifiedKey = 'unclassified';
export const serviceCategoryKeySuffix = '_service_category';
export const acupunctureKey = 'acupuncture';
export const bodyContouringKey = 'body_contouring';
export const daySpaKey = 'day_spa';
export const fillersKey = 'fillers';
export const hairRemovalKey = 'hair_removal';
export const hairRestorationKey = 'hair_restoration';
export const injectablesKey = 'injectables';
export const ivKey = 'iv';
export const medicalFacialsKey = 'medical__facial';
export const massageKey = 'massage';
export const nonMedicalFacialsKey = 'non_medical_facial';
export const otherMedicalKey = 'other_medical';
export const otherWellnessKey = 'other_wellness';
export const weightLossKey = 'weight_loss';
export const defaultCategories = [
  {
    key: acupunctureKey,
    label: 'Acupuncture',
  },
  {
    key: bodyContouringKey,
    label: 'Body Contouring',
  },
  {
    key: daySpaKey,
    label: 'Day Spa',
  },
  {
    key: fillersKey,
    label: 'Fillers',
  },
  {
    key: hairRemovalKey,
    label: 'Hair Removal',
  },
  {
    key: hairRestorationKey,
    label: 'Hair Restoration',
  },
  {
    key: injectablesKey,
    label: 'Injectables',
  },
  {
    key: ivKey,
    label: 'IV',
  },
  {
    key: medicalFacialsKey,
    label: 'Medical Facials',
  },
  {
    key: nonMedicalFacialsKey,
    label: 'Non-Medical Facials',
  },
  {
    key: otherMedicalKey,
    label: 'Other Medical',
  },
  {
    key: otherWellnessKey,
    label: 'Wellness',
  },
  {
    key: weightLossKey,
    label: 'Weight Loss',
  },
];
export const serviceCategoryLabels = {
  [acupunctureKey]: 'Acupuncture',
  [bodyContouringKey]: 'Body Contouring',
  [daySpaKey]: 'Day Spa',
  [fillersKey]: 'Fillers',
  [hairRemovalKey]: 'Hair Removal',
  [hairRestorationKey]: 'Hair Restoration',
  [injectablesKey]: 'Injectables',
  [ivKey]: 'IV',
  [medicalFacialsKey]: 'Medical Facials',
  [massageKey]: 'Massage',
  [nonMedicalFacialsKey]: 'Non-Medical Facials',
  [otherMedicalKey]: 'Other Medical',
  [otherWellnessKey]: 'Wellness',
  [weightLossKey]: 'Weight Loss',
  [unclassifiedKey]: 'Miscellaneous',
};
export const serviceCategoryLabelMap = {
  [acupunctureKey]: 'Acupuncture',
  [bodyContouringKey]: 'Body Contouring',
  [daySpaKey]: 'Day Spa',
  [fillersKey]: 'Fillers',
  [hairRemovalKey]: 'Hair Removal',
  [hairRestorationKey]: 'Hair Restoration',
  [injectablesKey]: 'Injectables',
  [ivKey]: 'IV',
  [medicalFacialsKey]: 'Medical Facials',
  [massageKey]: 'Massage',
  [nonMedicalFacialsKey]: 'Non-Medical Facials',
  [otherMedicalKey]: 'Other Medical',
  [otherWellnessKey]: 'Wellness',
  [weightLossKey]: 'Weight Loss',
  [unclassifiedKey]: 'Miscellaneous',
};

export const serviceTypeKey = 'service';
export const addOnServiceTypeKey = 'addOns';

export const facebookKey = 'facebook';
export const googleKey = 'google';
const semaglutideCampaignKey = 'semaglutide';
const botoxCampaignKey = 'botox';
export const ltvToCacRatioKeySuffix = '_ltv_cac_ratio';
export const leadConversionKeySuffix = '_lead_conversion';

export const campaignLeadConversionLabels = {
  [`${semaglutideCampaignKey}_${facebookKey}${leadConversionKeySuffix}`]:
    'Semaglutide Campaign - Facebook',
  [`${semaglutideCampaignKey}_${googleKey}${leadConversionKeySuffix}`]:
    'Semaglutide Campaign - Google',
  [`${botoxCampaignKey}_${facebookKey}${leadConversionKeySuffix}`]:
    'Botox Campaign - Facebook',
  [`${botoxCampaignKey}_${googleKey}${leadConversionKeySuffix}`]:
    'Botox Campaign - Google',
};

export const campaignLtvToCacLabels = {
  [`${semaglutideCampaignKey}_${facebookKey}${ltvToCacRatioKeySuffix}`]:
    'Semaglutide Campaign - Facebook',
  [`${semaglutideCampaignKey}_${googleKey}${ltvToCacRatioKeySuffix}`]:
    'Semaglutide Campaign - Google',
  [`${botoxCampaignKey}_${facebookKey}${ltvToCacRatioKeySuffix}`]:
    'Botox Campaign - Facebook',
  [`${botoxCampaignKey}_${googleKey}${ltvToCacRatioKeySuffix}`]:
    'Botox Campaign - Google',
};

export const numberDataType = 'number';
export const percentDataType = 'percent';

export const tooltipValueType = 'value';
export const tooltipBenchmarkType = 'benchmark';

const tiktokKey = 'tiktok';
const yelpKey = 'yelp';
const organicKey = 'organic';

export const leadSourceLabels = {
  [facebookKey]: 'Facebook',
  [googleKey]: 'Google',
  [tiktokKey]: 'Tiktok',
  [yelpKey]: 'Yelp',
  [organicKey]: 'Organic',
};

export const emrBookingPlatformKey = 'emr_booking_platform';
export const reviewPlatformOptions = [
  {
    key: googleKey,
    label: 'Google Business',
  },
  {
    key: yelpKey,
    label: 'Yelp',
  },
  { key: emrBookingPlatformKey, label: 'EMR booking page (if applicable)' },
];

// Figures
export const revenueByOffering = 'revenue-by-offering';
export const revenueByService = 'revenue-by-service';
export const campaignLeadConversion = 'campaign-lead-conversion';
export const campaignLtvCacRatio = 'campaign-ltv-cac-ratio';
export const revenuePerAppointmentByStaffMember =
  'revenue-per-appointment-staff-member';
export const revenuePerAppointmentByLeadSource =
  'revenue-per-appointment-lead-source';
export const profitLossPerService = 'profit-loss-per-service';

// Metrics
export const valuationMetricName = 'valuation';
export const revenueMetricName = 'revenue';
export const ebitdaMetricName = 'ebitda';
export const ebitdaMarginMetricName = 'ebitda-margin';
export const leadConversionPercentageMetricName = 'lead-conversion-percentage';
export const ltvToCacRatioMetricName = 'ltv-cac-ratio';
export const adSpendMetricName = 'ad-spend';
export const newClientsWonMetricName = 'new-clients-won';
export const numLeadsMetricName = 'total-leads';
export const scheduleCapacityMetricName = 'schedule-capacity';
export const numAppointmentsMetricName = 'num-appointments';
export const cancellationsAndNoShowsMetricName =
  'num-cancellations-and-no-shows';
export const revenuePerAppointmentMetricName = 'revenue-per-appointment';
export const expensesMetricName = 'expenses';
export const profitLossPerServiceMetricName = 'profit-loss-per-service';

export const metrics = [
  {
    key: valuationMetricName,
    label: 'Valuation',
    type: dollarInputType,
  },
  {
    key: ebitdaMetricName,
    label: 'EBITDA',
    type: dollarInputType,
  },
  {
    key: ebitdaMarginMetricName,
    label: 'EBITDA Margin',
    type: percentInputType,
  },
  {
    key: revenueMetricName,
    label: 'Revenue',
    type: dollarInputType,
  },
  {
    key: adSpendMetricName,
    label: 'Ad Spend',
    type: dollarInputType,
  },
  {
    key: leadConversionPercentageMetricName,
    label: 'Lead Conversion Percentage',
    type: percentInputType,
  },
  {
    key: ltvToCacRatioMetricName,
    label: 'LTV:CAC Ratio',
    type: floatInputType,
  },
  {
    key: newClientsWonMetricName,
    label: 'New Clients Won',
    type: integerInputType,
  },
];

export const metricsLabelObject = {
  [valuationMetricName]: 'Valuation',
  [ebitdaMetricName]: 'EBITDA',
  [ebitdaMarginMetricName]: 'EBITDA Margin',
  [revenueMetricName]: 'Revenue',
  [adSpendMetricName]: 'Ad Spend',
  [leadConversionPercentageMetricName]: 'Lead Conversion Percentage',
  [ltvToCacRatioMetricName]: 'LTV:CAC Ratio',
  [newClientsWonMetricName]: 'New Clients Won',
  [scheduleCapacityMetricName]: 'Schedule Capacity',
  [numAppointmentsMetricName]: 'Number of Appointments',
  [cancellationsAndNoShowsMetricName]: 'No-Shows & Cancellations',
  [revenuePerAppointmentMetricName]: 'Revenue Per Appointment',
};

export const metricsTypeObject = {
  [valuationMetricName]: dollarInputType,
  [ebitdaMetricName]: dollarInputType,
  [ebitdaMarginMetricName]: percentInputType,
  [revenueMetricName]: dollarInputType,
  [adSpendMetricName]: dollarInputType,
  [leadConversionPercentageMetricName]: percentInputType,
  [ltvToCacRatioMetricName]: floatInputType,
};

export const equalOperator = '=';
export const greaterThanOrEqualToOperator = '>=';
export const greaterThanOperator = '>';
export const lesserThanOperator = '<';
export const lesserThanOrEqualToOperator = '<=';

export const operators = [
  {
    key: equalOperator,
    label: equalOperator,
  },
  {
    key: greaterThanOrEqualToOperator,
    label: greaterThanOrEqualToOperator,
  },
  {
    key: greaterThanOperator,
    label: greaterThanOperator,
  },
  {
    key: lesserThanOrEqualToOperator,
    label: lesserThanOrEqualToOperator,
  },
  {
    key: lesserThanOperator,
    label: lesserThanOperator,
  },
];

export const finiteType = 'finite';
export const infiniteType = 'infinite';

export const goalTypes = [
  {
    key: finiteType,
    label: 'A finite goal with a due date (i.e. End of Quarter Revenue)',
  },
  {
    key: infiniteType,
    label:
      'An infinite goal that I want to constantly strive towards indefinitely (i.e. Staff Churn rate)',
  },
];

export const goalStatuses = [
  {
    key: upcomingStatusKey,
    label: 'Upcoming',
  },
  {
    key: inProgressStatusKey,
    label: 'In Progress',
  },
  {
    key: completeStatusKey,
    label: 'Completed',
  },
];

export const descendingKey = 'desc';
export const ascendingKey = 'asc';
export const descendingEbitdaSortKey = 'ebitda_desc';
export const ascendingEbitdaSortKey = 'ebitda_asc';
export const descendingRevenueSortKey = 'revenue_desc';
export const ascendingRevenueSortKey = 'revenue_asc';
export const descendingValuationSortKey = 'valuation_desc';
export const ascendingValuationSortKey = 'valuation_asc';
export const descendingDateLaunchedSortKey = 'date_launched_desc';
export const ascendingDateLaunchedSortKey = 'date_launched_asc';
export const descendingProductionSortKey = 'production_desc';
export const ascendingProductionSortKey = 'production_asc';
export const descendingJoinDateSortKey = 'join_date_desc';
export const ascendingJoinDateSortKey = 'join_date_asc';

export const sortFieldLabelMap = {
  [descendingEbitdaSortKey]: 'EBITDA (Desc.)',
  [ascendingEbitdaSortKey]: 'EBITDA (Asc.)',
  [descendingRevenueSortKey]: 'Revenue (Desc.)',
  [ascendingRevenueSortKey]: 'Revenue (Asc.)',
  [descendingValuationSortKey]: 'Valuation (Desc.)',
  [ascendingValuationSortKey]: 'Valuation (Asc.)',
  [descendingDateLaunchedSortKey]: 'Launch Date (Newest)',
  [ascendingDateLaunchedSortKey]: 'Launch Date (Oldest)',
  [descendingProductionSortKey]: 'Production (Desc.)',
  [ascendingProductionSortKey]: 'Production (Asc.)',
  [descendingJoinDateSortKey]: 'Join Date (Newest)',
  [ascendingJoinDateSortKey]: 'Join Date (Oldest)',
};

export const stagingFilterConversionDelay = 300;
export const autoSaveDelayMs = 5000;
export const buttonTriggeredAutoSaveDelayMs = 400;

export const selectActionKey = 'select';
export const unselectActionKey = 'unselect';
export const replaceActionKey = 'replace';
export const monitoringAllActionKey = 'monitoring_all';
export const selectionActions = [
  selectActionKey,
  unselectActionKey,
  replaceActionKey,
  monitoringAllActionKey,
];

// Scheduling actions
export const affirmationActionKey = 'affirmation';
export const agentActionKey = 'agent';
export const alreadyBookedActionKey = 'already_booked';
export const availabilityActionKey = 'availability';
export const bookLaterActionKey = 'book_later';
export const browseCatalogActionKey = 'browse_catalog';
export const cancelApptActionKey = 'cancel_appt';
export const confirmBookingActionKey = 'confirm_booking';
export const disengagementActionKey = 'disengagement';
export const endOfChatActionKey = 'end_of_chat';
export const faqActionKey = 'faq';
export const feedbackActionKey = 'feedback';
export const lastVisitInfoActionKey = 'last_visit_info';
export const makingConversationActionKey = 'making_conversation';
export const multiPersonBookingActionKey = 'multi_person_booking';
export const multiSessionBookingActionKey = 'multi_session_booking';
export const negationActionKey = 'negation';
export const packageInquiryActionKey = 'package_inquiry';
export const paymentInfoActionKey = 'payment_info';
export const pricingActionKey = 'pricing';
export const rescheduleActionKey = 'reschedule';
export const returnPolicyActionKey = 'return_policy';
export const runningLateActionKey = 'running_late';
export const sendPicsActionKey = 'send_pics';
export const staffActionKey = 'staff';
export const unsubscribeActionKey = 'unsubscribe';
export const wrongContactInfoActionKey = 'wrong_contact_info';

export const unsupportedActions = [rescheduleActionKey, cancelApptActionKey];

export const alreadyHaveApptActionKey = 'already_have_appt';
export const existingCustomerActionKey = 'existing_customer';
export const alreadyBookedActions = [
  alreadyBookedActionKey,
  alreadyHaveApptActionKey,
  existingCustomerActionKey,
];

export const singleLocationBusinessType = 'single_location';
export const multiLocationBusinessType = 'multi_location';
export const franchiseBusinessType = 'franchise';
export const businessTypes = [
  {
    key: singleLocationBusinessType,
    label: 'Single location business',
  },
  {
    key: multiLocationBusinessType,
    label: 'Multi-location business',
  },
  {
    key: franchiseBusinessType,
    label: 'Franchise business',
  },
];

export const singleKey = 'single';
export const multipleKey = 'multiple';
export const locationsUsingTypes = [
  {
    key: singleKey,
    label: 'I only plan on using LiveIQ at a single location',
  },
  {
    key: multipleKey,
    label: 'I plan on using LiveIQ at multiple locations',
  },
];

export const multiLocationBusinessOwnerRole = 'multi_location_business_owner';
export const singleLocationBusinessOwnerRole = 'single_location_business_owner';
export const locationAdminRole = 'location_admin';
export const providerRole = 'employee';
export const superAdminRole = 'super_admin';
export const supportAgentRole = 'support_agent';
export const consultantRole = 'consultant';
export const multiLocationPrivilegedRoles = [superAdminRole, consultantRole];
export const userRoles = [
  multiLocationBusinessOwnerRole,
  singleLocationBusinessOwnerRole,
  locationAdminRole,
  providerRole,
  superAdminRole,
  supportAgentRole,
  consultantRole,
];
export const userRoleLabelMap = {
  [supportAgentRole]: 'Human Support Agent',
  [providerRole]: 'Provider',
  [locationAdminRole]: 'Location Admin',
  [singleLocationBusinessOwnerRole]: 'Owner',
  [multiLocationBusinessOwnerRole]: 'Owner',
  [consultantRole]: 'Consultant',
};
export const userRoleOptions = [
  {
    key: supportAgentRole,
    label: 'Human Support Agent',
  },
  {
    key: providerRole,
    label: 'Provider',
  },
  {
    key: locationAdminRole,
    label: 'Location Admin',
  },
  {
    key: singleLocationBusinessOwnerRole,
    label: 'Single-Location Business Owner',
  },
  {
    key: multiLocationBusinessOwnerRole,
    label: 'Multi-Location Business Owner',
  },
  { key: consultantRole, label: 'Consultant' },
];

export const liveAppModeKey = 'live_app';
export const demoAppModeKey = 'demo';
export const appModeCookieKey = 'app_mode';

export const collapsedModulesCookieKey = 'collapsed_modules';
export const collapsedSubmodulesCookieKey = 'collapsed_submodules';
export const expandedSubmodulesCookieKey = 'expanded_submodules';
export const selectedSubmoduleCookieKey = 'selected_submodule';
export const collapsedCategoryIdsCookieKey = 'collapsed_category_ids';
export const selectedPromptCookieKey = 'selected_prompt';
export const businessTabCookieKey = 'business_tab_cookie';
export const contentTabCookieKey = 'content_tab_cookie';
export const chatDemoTabCookieKey = 'chat_demo_tab_cookie';
export const audienceSearchParamsCookieKey = 'audience_search_params';
export const clientSearchParamsCookieKey = 'client_search_params';
export const chatsSearchParamsCookieKey = 'chats_search_params';
export const sequencesTabCookieKey = 'sequences_tab';
export const contactNameForPipelineCookieKey =
  'contact_name_for_pipeline_cookie';
export const campaignForPipelineCookieKey = 'campaign_for_pipeline_cookie';
export const contactTypeForPipelineCookieKey =
  'contact_type_for_pipeline_cookie';
export const chatDemoCookieKey = 'chat_demo_cookie';
export const clientIdForChatDemoCookieKey = 'chat_demo_client_id_cookie';
export const campaignForChatDemoCookieKey = 'campaign_for_chat_demo_cookie';
export const demoNameCookieKey = 'demo_name_cookie';

export const campaignSortFieldCookieKey = 'campaign_sort_field';

export const chatLogsCookieKey = 'chat_logs_cookie';

export const clientProfileOpenIdCookieKey = 'client_profile_open_id_cookie';

export const nodeToEditIdCookieKey = 'node_to_edit_id';

export const serviceFollowUpChatScenario = 'service_follow_up';
export const bookingSupportChatScenario = 'booking_support';
export const chatScenarios = [
  serviceFollowUpChatScenario,
  bookingSupportChatScenario,
];

// Appointment/Schedule Item statuses
export const availableKey = 'available';
export const bookedKey = 'booked';
export const unavailableKey = 'unavailable';
export const inactiveKey = 'inactive';
export const demoStatusKey = 'demo_appointment_booked';
export const noShowStatusKey = 'no_show';
export const scheduledStatusKey = 'scheduled';
export const cancelledStatusKey = 'cancelled';
export const depositHoldStatusKey = 'deposit_hold';
export const depositExpiredStatusKey = 'deposit_expired';
export const scheduleItemStatuses = [
  availableKey,
  bookedKey,
  unavailableKey,
  demoStatusKey,
];
export const appointmentStatusLabelMap = {
  [scheduledStatusKey]: 'Scheduled',
  [cancelledStatusKey]: 'Cancelled',
  [depositHoldStatusKey]: 'Deposit Hold',
  [depositExpiredStatusKey]: 'Deposit Expired',
};

export const statusLabelMap = {
  [availableKey]: 'available for booking',
  [bookedKey]: 'appointment booked',
  [demoStatusKey]: 'demo appointment booked',
  [unavailableKey]: 'unavailable for booking',
  [inactiveKey]: 'not working',
};

export const easternTimezoneKey = 'America/New_York';
export const centralTimezoneKey = 'America/Chicago';
export const mountainTimezoneKey = 'America/Denver';
export const pacificTimezoneKey = 'America/Los_Angeles';
export const alaskaTimezoneKey = 'America/Anchorage';
export const hawaiiTimezoneKey = 'Pacific/Honolulu';

export const locationTimezones = [
  {
    key: easternTimezoneKey,
    label: 'Eastern',
  },
  {
    key: centralTimezoneKey,
    label: 'Central',
  },
  {
    key: mountainTimezoneKey,
    label: 'Mountain',
  },
  {
    key: pacificTimezoneKey,
    label: 'Pacific',
  },
  {
    key: alaskaTimezoneKey,
    label: 'Alaska',
  },
  {
    key: hawaiiTimezoneKey,
    label: 'Hawaii',
  },
];

export const clientReactivationAgentProductKey = 'client_reactivation';
export const leadConversionAgentProductKey = 'lead_conversion';
export const websiteSupportAgentProductKey = 'website_support';
export const juggernautAgentProductKey = 'juggernaut';
export const liveKey = 'live';
export const comingSoonKey = 'coming_soon';
export const agentProductMap = {
  [juggernautAgentProductKey]: {
    avatar:
      'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/images/agents/peter-2.png',
    defaultName: 'Ava',
    color: 'green',
    description: 'appointment-setting machine',
    status: liveKey,
  },
  [clientReactivationAgentProductKey]: {
    avatar:
      'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/images/agents/peter-2.png',
    defaultName: 'Peter',
    color: 'green',
    description: 'client reactivation wiz',
    status: liveKey,
  },
  [leadConversionAgentProductKey]: {
    avatar:
      'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/images/agents/candace-%60-Photoroom.png',
    defaultName: 'Laura',
    color: 'pink',
    description: 'lead conversion maestro',
    status: comingSoonKey,
  },
  [websiteSupportAgentProductKey]: {
    avatar:
      'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/images/agents/laura-2-Photoroom.png',
    defaultName: 'Candace',
    color: 'red',
    description: 'website support agent',
    status: comingSoonKey,
  },
};

export const pmsAppKey = 'pms';
export const ghlAppKey = ghlKey;

export const unconnectedAgentStatusKey = 'unconnected';
export const connectedAgentStatusKey = 'connected';

export const unconnectedStatusKey = 'unconnected';
export const connectedStatusKey = 'connected';
export const initializedStatusKey = 'initialized';

export const activeKey = 'active';
export const expiredKey = 'expired';
export const membershipStatuses = [activeKey, expiredKey, inactiveKey];
export const membershipStatusLabelMap = {
  [activeKey]: 'Member',
  [expiredKey]: 'Expired',
  [inactiveKey]: 'Never',
};

export const textSeparatorChar = '•';

export const canBeTextedKey = 'canBeTexted';
export const nameKey = 'name';
export const firstNameKey = 'firstName';
export const lastNameKey = 'lastName';
export const campaignsKey = 'campaigns';
export const lastMessagedAtKey = 'lastMessagedAt';
export const dndKey = 'dnd';
export const emailKey = 'email';
export const phoneKey = 'phone';
export const distanceKey = 'numMetresFromLocation';
export const membershipStatusKey = 'memberStatus';
export const numVisitsKey = 'numVisits';
export const firstVisitKey = 'daysSinceFirstEverAppointment';
export const lastVisitKey = 'daysSinceLastVisit';
export const upcomingVisitsKey = 'daysUntilNextUpcomingVisit';
export const numUpcomingVisitsKey = 'numUpcomingVisits';
export const serviceCategoriesUsedKey = 'serviceCategoriesUsed';
export const tagsKey = 'tags';

export const stringFieldType = 'string';
export const intFieldType = 'int';
export const int64FieldType = 'int64';
export const optionsFieldType = 'options';

export const emptyArrayValue = '__EMPTY__';
export const emptyStringValue = '-1';
export const emptyIntValue = -1;

// Undefined campaign
export const unknownCampaignKey = 'Unknown Campaign';

// Campaign member statuses
export const uncontactedCampaignMemberStatusKey = 'uncontacted';
export const contactedCampaignMemberStatusKey = 'contacted';
export const staleCampaignMemberStatusKey = 'stale';
export const declinedCampaignMemberStatusKey = 'declined';
export const optedOutCampaignMemberStatusKey = 'opted_out';
export const disqualifiedCampaignMemberStatusKey = 'disqualified';
export const hotLeadCampaignMemberStatusKey = 'hot_lead';
export const sequenceCompletedCampaignMemberStatusKey = 'sequence_completed';
export const successfullyBookedCampaignMemberStatusKey = 'successfully_booked';
export const campaignMemberStatusLabelMap = {
  [uncontactedCampaignMemberStatusKey]: 'Uncontacted',
  [contactedCampaignMemberStatusKey]: 'Contacted',
  [sequenceCompletedCampaignMemberStatusKey]: 'Stale',
  [staleCampaignMemberStatusKey]: 'Stale',
  [declinedCampaignMemberStatusKey]: 'Declined',
  [optedOutCampaignMemberStatusKey]: 'Opted-Out',
  [hotLeadCampaignMemberStatusKey]: 'Hot Lead',
  [successfullyBookedCampaignMemberStatusKey]: 'Successfully Booked',
};
export const campaignMemberStatuses = [
  uncontactedCampaignMemberStatusKey,
  contactedCampaignMemberStatusKey,
  staleCampaignMemberStatusKey,
  declinedCampaignMemberStatusKey,
  optedOutCampaignMemberStatusKey,
  hotLeadCampaignMemberStatusKey,
  successfullyBookedCampaignMemberStatusKey,
];

// Contact types
export const contactTypeKey = 'type';
export const clientContactType = 'client';
export const memberContactType = 'member';
export const leadContactType = 'lead';
export const internalContactType = 'internal';
export const contactTypeLabelMap = {
  [clientContactType]: 'Client',
  [leadContactType]: 'Lead',
  [internalContactType]: 'Internal',
};
export const contactTypesArrForFilter = [clientContactType, leadContactType];
export const contactTypesArr = [allKey, ...contactTypesArrForFilter];
export const contactTypesForChatsArr = [
  ...contactTypesArrForFilter,
  internalContactType,
];
export const contactTypesForCampaigns = [
  {
    key: allKey,
    label: 'All Contacts',
  },
  {
    key: clientContactType,
    label: 'Clients',
  },
  {
    key: leadContactType,
    label: 'Leads',
  },
];
export const contactTypes = [
  ...contactTypesForCampaigns,
  {
    key: internalContactType,
    label: 'Internal',
  },
];
export const locationContactTypes = [
  {
    key: memberContactType,
    label: 'Members',
  },
  {
    key: clientContactType,
    label: 'Clients',
  },
  {
    key: leadContactType,
    label: 'Leads',
  },
];

export const getContactTypeLabel = (contactType) => {
  return contactType === allKey
    ? `Clients & Leads`
    : `${contactTypeLabelMap[contactType]}s`;
};

export const intraStringSeparatorChar = '----';
export const interStringSeparatorChar = `______`;

// db job keys
export const sessionTypeResyncJobKey = 'session_type_resync';

export const clientFilterFieldLabelMap = {
  [canBeTextedKey]: {
    label: 'Contactable',
    extendedLabel: 'Contactable',
    type: optionsFieldType,
    labelMap: booleanLabelMap,
    display: false,
  },
  [nameKey]: { label: 'Name', extendedLabel: 'Name', type: stringFieldType },
  [contactTypeKey]: {
    label: 'Type',
    extendedLabel: 'Type',
    type: optionsFieldType,
    labelMap: contactTypeLabelMap,
  },
  [campaignsKey]: {
    label: 'Campaigns',
    extendedLabel: 'Campaign Enrollment',
    type: optionsFieldType,
    labelMap: campaignMemberStatusLabelMap,
  },
  [lastMessagedAtKey]: {
    label: 'Contacted',
    extendedLabel: 'Last Contacted (days ago)',
    type: int64FieldType,
  },
  [dndKey]: {
    label: 'DND',
    extendedLabel: 'Do Not Disturb',
    type: optionsFieldType,
    labelMap: booleanLabelMap,
  },
  [emailKey]: {
    label: 'Email',
    extendedLabel: 'Email Address',
    type: stringFieldType,
  },
  [phoneKey]: {
    label: 'Phone',
    extendedLabel: 'Phone Number',
    type: stringFieldType,
  },
  [membershipStatusKey]: {
    label: 'Member',
    extendedLabel: 'Membership Status',
    type: optionsFieldType,
    labelMap: membershipStatusLabelMap,
  },
  [numVisitsKey]: {
    label: 'Visits',
    extendedLabel: 'Number of Past Visits',
    type: intFieldType,
  },
  [lastVisitKey]: {
    label: 'Last Visit',
    extendedLabel: 'Last Visit',
    type: intFieldType,
    units: 'days ago',
  },
  [upcomingVisitsKey]: {
    label: 'Upcoming Visits',
    extendedLabel: 'Next Upcoming Visit',
    type: intFieldType,
    units: 'days until',
  },
  [serviceCategoriesUsedKey]: {
    label: 'Services Used',
    extendedLabel: 'Services Used',
    type: optionsFieldType,
  },
  [tagsKey]: {
    label: 'Tags',
    extendedLabel: 'Tags',
    type: optionsFieldType,
  },
};
export const clientFilterFieldKeys = Object.keys(clientFilterFieldLabelMap);
const displayedClientFilterFieldKeys = clientFilterFieldKeys.filter(
  (k) => k !== canBeTextedKey,
);
export const getDisplayedClientFilterFieldKeys = (context) => {
  return context === campaignMemberManagementContextKey
    ? displayedClientFilterFieldKeys.filter((k) => k !== dndKey)
    : displayedClientFilterFieldKeys;
};

export const extendedClientFilterFieldLabelMap = {
  ...clientFilterFieldLabelMap,
  [firstNameKey]: {
    label: 'First name',
    extendedLabel: 'First name',
    type: stringFieldType,
  },
  [lastNameKey]: {
    label: 'Last name',
    extendedLabel: 'Last name',
    type: stringFieldType,
  },
};

export const typesenseContactSchemaName = 'contacts';
export const typesenseChatsSchemaName = 'chats';

// Client search fields
export const nameField = 'name';
export const firstNameField = 'firstName';
export const lastNameField = 'lastName';
export const memberStatusField = 'memberStatus';
export const numVisitsField = 'numVisits';
export const daysSinceLastVisitField = 'daysSinceLastVisit';
export const numUpcomingVisitsField = 'numUpcomingVisits';
export const nextUpcomingVisitField = 'nextUpcomingVisit';
export const daysUntilNextUpcomingVisitField = 'daysUntilNextUpcomingVisit';
export const lastMessagedAtField = 'lastMessagedAt';

// Chat search fields
export const callIdsField = 'callIds';
export const chatCampaignField = 'campaignIds';
export const chatContactTypeField = 'userType';
export const chatStatusField = 'status';
export const chatMediumField = 'medium';
export const bookedAppointmentIdField = 'bookedAppointmentId';
export const appointmentStatusesField = 'appointmentStatuses';
export const availsFetchedMessageContent = 'avails_fetched';
export const appointmentBookedMessageContent = 'appointment_booked';
export const appointmentRescheduledMessageContent = 'appointment_rescheduled';
export const appointmentCancelledMessageContent = 'appointment_cancelled';
export const appointmentErrorMessageContent = 'appointment_error';
export const appointmentRequiresDepositMessageContent =
  'appointment_requires_deposit';
export const appointmentMonitoringMessageContent = 'appointment_monitoring';
export const lastMessageSentAtField = 'lastMessageSentAt';
export const lastMessageSenderField = 'lastMessageSender';
export const numTotalMessagesField = 'numTotalMessages';
export const numAssistantMessagesField = 'numAssistantMessages';
export const numUserMessagesField = 'numUserMessages';
export const sequenceIdsField = 'sequenceIds';
export const numHumanAgentMessagesField = 'numHumanAgentMessages';
export const disengagementReasonField = 'disengagementReason';
export const interceptionReasonField = 'interceptionReason';
export const endOfExchangeChatMessageContent = 'endOfExchange';
export const chatResumedMessageContent = 'chat_resumed';
export const lastFollowUpMessageContent = 'last_follow_up';
export const staleMessageContent = 'stale';

// Call messages
export const newCallChatEvent = 'new_call_chat_event';
export const callEndedChatEvent = 'call_ended_chat_event';
export const callbackRequestedChatEvent = 'callback_requested_chat_event';
export const callEvents = [newCallChatEvent, callEndedChatEvent];
export const newCallMessageContent = 'new_call';
export const callEndedMessageContent = 'call_ended';
export const callbackRequestedMessageContent = 'callback_requested';

// End of exchange reasons
export const noTasksFoundEndOfExchangeReasonKey = 'no_tasks_found';
export const unsupportedActionEndOfExchangeReasonKey = 'unsupported_action';

export const appointmentMonitoringStatus = 'appointment_monitoring';
export const appointmentBookedStatus = 'appointment_booked';
export const appointmentErrorStatus = 'appointment_error';
export const appointmentDepositRequiredStatus = 'appointment_deposit_required';
export const chatAppointmentStatuses = [
  appointmentMonitoringStatus,
  appointmentBookedStatus,
  emptyArrayValue,
  appointmentDepositRequiredStatus,
];
export const chatAppointmentStatusMap = {
  [appointmentBookedStatus]: 'Booked',
  [appointmentMonitoringStatus]: 'Monitoring',
  [appointmentDepositRequiredStatus]: 'Deposit required',
  [emptyArrayValue]: 'None',
};

// Chat media
export const webDemoChatMediumKey = 'web_demo';
export const smsDemoChatMediumKey = 'sms_demo';
export const webChatMediumKey = 'web_production';
export const smsChatMediumKey = 'sms_production';
export const demoChatMediumKey = 'demo';
export const productionChatMediumKey = 'production';
export const chatMedia = [
  demoChatMediumKey,
  productionChatMediumKey,
  smsChatMediumKey,
  webDemoChatMediumKey,
  smsDemoChatMediumKey,
];
export const productionChatMedia = [productionChatMediumKey, smsChatMediumKey];

// Chat statuses
export const activeChatStatusKey = 'active';
export const disengagedChatStatusKey = 'disengaged';
export const interceptedChatStatusKey = 'intercepted';
export const disengagedThenInterceptedChatStatusKey = `${disengagedChatStatusKey}__${interceptedChatStatusKey}`;
export const chatStatuses = [
  activeChatStatusKey,
  disengagedChatStatusKey,
  interceptedChatStatusKey,
  disengagedThenInterceptedChatStatusKey,
];

// Chat sender types
export const assistantKey = 'assistant';
export const userKey = 'user';
export const humanAgentKey = 'human_agent';
export const systemKey = 'system';
export const senderTypes = [assistantKey, userKey, systemKey, humanAgentKey];
export const senderTypeLabelMap = {
  [assistantKey]: 'LiveIQ Agent',
  [humanAgentKey]: 'Human Support Agent',
};

// Chat appointment bookers
export const chatAppointmentBookers = [humanAgentKey, assistantKey];

export const chatFilterFieldLabelMap = {
  [numUserMessagesField]: {
    label: 'Number of User Messages',
    extendedLabel: 'Number of User Messages',
    type: intFieldType,
  },
  [chatMediumField]: {
    label: 'Medium',
    extendedLabel: 'Medium',
    type: optionsFieldType,
  },
  [chatStatusField]: {
    label: 'Status',
    extendedLabel: 'Status',
    type: optionsFieldType,
  },
  [bookedAppointmentIdField]: {
    label: 'Booked Appointment',
    extendedLabel: 'Booked Appointment',
    type: stringFieldType,
  },
};
export const chatStatusLabelMap = {
  [activeChatStatusKey]: 'Active',
  [interceptedChatStatusKey]: 'Intercepted',
  [disengagedChatStatusKey]: 'Disengaged',
  [disengagedThenInterceptedChatStatusKey]: 'Disengaged then Intercepted',
};
export const chatMediumLabelMap = {
  [productionChatMediumKey]: 'Real',
  [demoChatMediumKey]: 'Demo',
  [smsChatMediumKey]: 'Real SMS',
  [webDemoChatMediumKey]: 'Demo Web',
  [smsDemoChatMediumKey]: 'Demo SMS',
};

// Chat disengagement reasons
export const optOutDisengagementReasonKey = 'opt_out';
export const wrongNumberDisengagementReasonKey = 'wrong_number';
export const movedAwayNumberDisengagementReasonKey = 'moved_away';
export const agentIncompetenceDisengagementReasonKey = 'agent_incompetence';
export const discontentDisengagementReasonKey = 'discontent';
export const requestsHumanDisengagementReasonKey = 'requests_human';
export const irrelevantDiscussionDisengagementReasonKey =
  'irrelevant_discussion';
export const benignDisengagementReasonKey = 'benign_disengagement';
export const successfulBookingDisengagementReasonKey = 'successful_booking';
export const bookingRequiresDepositDisengagementReasonKey =
  'booking_requires_deposit';
export const chatDisengagementReasons = [
  optOutDisengagementReasonKey,
  wrongNumberDisengagementReasonKey,
  movedAwayNumberDisengagementReasonKey,
  agentIncompetenceDisengagementReasonKey,
  discontentDisengagementReasonKey,
  requestsHumanDisengagementReasonKey,
  irrelevantDiscussionDisengagementReasonKey,
  benignDisengagementReasonKey,
  emptyStringValue,
  successfulBookingDisengagementReasonKey,
  bookingRequiresDepositDisengagementReasonKey,
];
export const chatDisengagementReasonLabelMap = {
  [optOutDisengagementReasonKey]: 'User opted-out',
  [wrongNumberDisengagementReasonKey]: 'Wrong number',
  [movedAwayNumberDisengagementReasonKey]: 'User moved away',
  [agentIncompetenceDisengagementReasonKey]: `Couldn't help`,
  [discontentDisengagementReasonKey]: `User was discontent`,
  [requestsHumanDisengagementReasonKey]: `User requested human`,
  [irrelevantDiscussionDisengagementReasonKey]: `User began irrelevant discussion`,
  [benignDisengagementReasonKey]: `Human made agent disengage`,
  [successfulBookingDisengagementReasonKey]: `Successfully booked appointment`,
  [bookingRequiresDepositDisengagementReasonKey]: `Confirmed interest - deposit required`,
};

// Chat interception reasons
export const agentErrorInterceptionReasonKey = 'agent_error';
export const userFrustratedInterceptionReasonKey = 'user_frustrated';
export const requestsHumanInterceptionReasonKey = 'requests_human';
export const mustClarifyInterceptionReasonKey = 'must_clarify';
export const closingTheSaleInterceptionReasonKey = 'closing_sale';
export const upsellInterceptionReasonKey = 'upsell';
export const usingSeparatePlatformlInterceptionReasonKey =
  'using_separate_platform';
export const agentNoLongerNeededToEngageInterceptionReasonKey =
  'agent_no_longer_needed';
export const chatInterceptionReasons = [
  agentErrorInterceptionReasonKey,
  requestsHumanInterceptionReasonKey,
  mustClarifyInterceptionReasonKey,
  upsellInterceptionReasonKey,
  closingTheSaleInterceptionReasonKey,
  emptyStringValue,
  usingSeparatePlatformlInterceptionReasonKey,
  agentNoLongerNeededToEngageInterceptionReasonKey,
];
export const chatInterceptionReasonLabelMap = {
  [agentErrorInterceptionReasonKey]: 'Atoning for agent error',
  [userFrustratedInterceptionReasonKey]: 'User frustrated',
  [requestsHumanInterceptionReasonKey]: 'User requested human',
  [mustClarifyInterceptionReasonKey]: 'Clarifying for the user',
  [closingTheSaleInterceptionReasonKey]: `Closing the sale`,
  [upsellInterceptionReasonKey]: `Up-selling`,
  [usingSeparatePlatformlInterceptionReasonKey]: `Chat continued on separate platform`,
  [agentNoLongerNeededToEngageInterceptionReasonKey]: `Agent no longer needed`,
};
export const chatInterceptionReasonDropdownOptions = [
  {
    key: agentErrorInterceptionReasonKey,
    label: 'The agent messed up',
  },
  {
    key: userFrustratedInterceptionReasonKey,
    label: 'The user is frustrated',
  },
  {
    key: requestsHumanInterceptionReasonKey,
    label: 'The user requested a human',
  },
  {
    key: mustClarifyInterceptionReasonKey,
    label: 'I want to clarify something for the user',
  },
  {
    key: closingTheSaleInterceptionReasonKey,
    label: 'I want to close the sale',
  },
  {
    key: upsellInterceptionReasonKey,
    label: 'I want to upsell the customer',
  },
  {
    key: usingSeparatePlatformlInterceptionReasonKey,
    label: 'Interaction continuing on another platform',
  },
  {
    key: agentNoLongerNeededToEngageInterceptionReasonKey,
    label: 'Agent no longer needed',
  },
];

// Chat resumption
export const agentUnresponsiveResumptionReasonKey = 'agent_unresponsive';

export const equalsKey = 'equals';
export const doesNotEqualKey = 'not_equal';
export const greaterThanKey = 'gt';
export const greaterThanOrEqualToKey = 'gte';
export const lessThanKey = 'lt';
export const lessThanOrEqualToKey = 'lte';
export const containsArrayItems = 'contains_array_items';
export const dynamicKey = 'dynamic';
export const staticKey = 'static';

export const twilioMessagingPlatformKey = 'twilio';
export const leadConnectorMessagingPlatformKey = 'lead_connector';

export const stagingStatusKey = 'staging';
export const liveStatusKey = liveKey;
export const pausedStatusKey = 'paused';

export const campaignStatusLabelMap = {
  [inProgressStatusKey]: 'Needs contacts added',
  [stagingStatusKey]: 'Ready to launch',
  [pausedStatusKey]: 'Awaiting relaunch',
  [liveStatusKey]: 'Live with contacts',
};

export const statusFieldKey = 'status';
export const nameFieldKey = 'name';
export const updatedAtFieldKey = 'updatedAt';
export const defaultSortOptions = [
  {
    key: nameFieldKey,
    label: 'Name',
  },
  {
    key: statusFieldKey,
    label: 'Status',
  },
  {
    key: updatedAtFieldKey,
    label: 'Updated at',
  },
];

export const masterContactsContextKey = 'master-contacts-context';
export const campaignMemberManagementContextKey =
  'campaign-member-management-context';
export const chatDemoContextKey = 'chat-demo-context';

export const newAppointmentsCampaignTypeKey = 'new_appointments';
export const serviceFollowUpCampaignTypeKey = 'service_follow_up';
export const scheduleFillUpCampaignTypeKey = 'schedule_fill_up';

export const campaignTypes = [
  {
    key: newAppointmentsCampaignTypeKey,
    label: 'New Appointments',
  },
  {
    key: serviceFollowUpCampaignTypeKey,
    label: 'Service Follow-Up',
  },
  {
    key: scheduleFillUpCampaignTypeKey,
    label: 'Schedule Fill-Up',
  },
];
export const campaignTypeLabelMap = {
  [newAppointmentsCampaignTypeKey]: 'New Bookings',
  [serviceFollowUpCampaignTypeKey]: 'Service Follow-Up',
  [scheduleFillUpCampaignTypeKey]: 'Schedule Fill-up',
};

export const mondayKey = 'monday';
export const tuesdayKey = 'tuesday';
export const wednesdayKey = 'wednesday';
export const thursdayKey = 'thursday';
export const fridayKey = 'friday';
export const saturdayKey = 'saturday';
export const sundayKey = 'sunday';

export const daysOfWeek = [
  mondayKey,
  tuesdayKey,
  wednesdayKey,
  thursdayKey,
  fridayKey,
  saturdayKey,
  sundayKey,
];
export const orderedDaysOfWeekMap = {
  [mondayKey]: 0,
  [tuesdayKey]: 1,
  [wednesdayKey]: 2,
  [thursdayKey]: 3,
  [fridayKey]: 4,
  [saturdayKey]: 5,
  [sundayKey]: 6,
};

export const hours = [
  {
    key: '06:00:00',
    label: '6:00 AM',
  },
  {
    key: '06:15:00',
    label: '6:15 AM',
  },
  {
    key: '06:30:00',
    label: '6:30 AM',
  },
  {
    key: '06:45:00',
    label: '6:45 AM',
  },
  {
    key: '07:00:00',
    label: '7:00 AM',
  },
  {
    key: '07:15:00',
    label: '7:15 AM',
  },
  {
    key: '07:30:00',
    label: '7:30 AM',
  },
  {
    key: '07:45:00',
    label: '7:45 AM',
  },
  {
    key: '08:00:00',
    label: '8:00 AM',
  },
  {
    key: '08:15:00',
    label: '8:15 AM',
  },
  {
    key: '08:30:00',
    label: '8:30 AM',
  },
  {
    key: '08:45:00',
    label: '8:45 AM',
  },
  {
    key: '09:00:00',
    label: '9:00 AM',
  },
  {
    key: '09:15:00',
    label: '9:15 AM',
  },
  {
    key: '09:30:00',
    label: '9:30 AM',
  },
  {
    key: '09:45:00',
    label: '9:45 AM',
  },
  {
    key: '10:00:00',
    label: '10:00 AM',
  },
  {
    key: '10:15:00',
    label: '10:15 AM',
  },
  {
    key: '10:30:00',
    label: '10:30 AM',
  },
  {
    key: '10:45:00',
    label: '10:45 AM',
  },
  {
    key: '11:00:00',
    label: '11:00 AM',
  },
  {
    key: '11:15:00',
    label: '11:15 AM',
  },
  {
    key: '11:30:00',
    label: '11:30 AM',
  },
  {
    key: '11:45:00',
    label: '11:45 AM',
  },
  {
    key: '12:00:00',
    label: '12:00 PM',
  },
  {
    key: '12:15:00',
    label: '12:15 PM',
  },
  {
    key: '12:30:00',
    label: '12:30 PM',
  },
  {
    key: '12:45:00',
    label: '12:45 PM',
  },
  {
    key: '13:00:00',
    label: '1:00 PM',
  },
  {
    key: '13:15:00',
    label: '1:15 PM',
  },
  {
    key: '13:30:00',
    label: '1:30 PM',
  },
  {
    key: '13:45:00',
    label: '1:45 PM',
  },
  {
    key: '14:00:00',
    label: '2:00 PM',
  },
  {
    key: '14:15:00',
    label: '2:15 PM',
  },
  {
    key: '14:30:00',
    label: '2:30 PM',
  },
  {
    key: '14:45:00',
    label: '2:45 PM',
  },
  {
    key: '15:00:00',
    label: '3:00 PM',
  },
  {
    key: '15:15:00',
    label: '3:15 PM',
  },
  {
    key: '15:30:00',
    label: '3:30 PM',
  },
  {
    key: '15:45:00',
    label: '3:45 PM',
  },
  {
    key: '16:00:00',
    label: '4:00 PM',
  },
  {
    key: '16:15:00',
    label: '4:15 PM',
  },
  {
    key: '16:30:00',
    label: '4:30 PM',
  },
  {
    key: '16:45:00',
    label: '4:45 PM',
  },
  {
    key: '17:00:00',
    label: '5:00 PM',
  },
  {
    key: '17:15:00',
    label: '5:15 PM',
  },
  {
    key: '17:30:00',
    label: '5:30 PM',
  },
  {
    key: '17:45:00',
    label: '5:45 PM',
  },
  {
    key: '18:00:00',
    label: '6:00 PM',
  },
  {
    key: '18:15:00',
    label: '6:15 PM',
  },
  {
    key: '18:30:00',
    label: '6:30 PM',
  },
  {
    key: '18:45:00',
    label: '6:45 PM',
  },
  {
    key: '19:00:00',
    label: '7:00 PM',
  },
  {
    key: '19:15:00',
    label: '7:15 PM',
  },
  {
    key: '19:30:00',
    label: '7:30 PM',
  },
  {
    key: '19:45:00',
    label: '7:45 PM',
  },
  {
    key: '20:00:00',
    label: '8:00 PM',
  },
  {
    key: '20:15:00',
    label: '8:15 PM',
  },
  {
    key: '20:30:00',
    label: '8:30 PM',
  },
  {
    key: '20:45:00',
    label: '8:45 PM',
  },
  {
    key: '21:00:00',
    label: '9:00 PM',
  },
  {
    key: '21:15:00',
    label: '9:15 PM',
  },
  {
    key: '21:30:00',
    label: '9:30 PM',
  },
];

const earliestSendableHour = '09:00:00';
let earliestSendableHourIdx;
const latestSendableHour = '21:00:00';
let latestSendableHourIdx;
hours.map((h, idx) => {
  if (h.key === earliestSendableHour) {
    earliestSendableHourIdx = idx;
  }
  if (h.key === latestSendableHour) {
    latestSendableHourIdx = idx;
  }
});
export const sendableSequenceHours = hours.slice(
  earliestSendableHourIdx,
  latestSendableHourIdx + 1,
);

export const superAdminUserRole = 'super_admin';

// Prompt types
export const stagingTemplatePromptTypeKey = 'staging_template';
export const productionTemplatePromptTypeKey = 'production_template';
export const renderedPromptTypeKey = 'rendered';
export const assistantCreationInstructionsPromptTypeKey =
  'assistant_creation_instructions';
export const runInstructionsPromptTypeKey = 'run_instructions';
export const promptTypes = [
  stagingTemplatePromptTypeKey,
  productionTemplatePromptTypeKey,
  renderedPromptTypeKey,
];

// Redaction
export const fakeLocationName = 'City MedSpa';
export const fakeNames = [
  { firstName: 'Nikky', lastName: 'Mara' },
  { firstName: 'Ethan', lastName: 'Smith' },
  { firstName: 'Olivia', lastName: 'Jones' },
  { firstName: 'Lucas', lastName: 'Brown' },
  { firstName: 'Ava', lastName: 'Taylor' },
  { firstName: 'Liam', lastName: 'Anderson' },
  { firstName: 'Sophia', lastName: 'Wilson' },
  { firstName: 'Mason', lastName: 'Davis' },
  { firstName: 'Isabella', lastName: 'Clark' },
  { firstName: 'Liam', lastName: 'Martinez' },
  { firstName: 'Emma', lastName: 'Rodriguez' },
  { firstName: 'Oliver', lastName: 'Hernandez' },
  { firstName: 'Amelia', lastName: 'Lopez' },
  { firstName: 'Elijah', lastName: 'Gonzalez' },
  { firstName: 'Charlotte', lastName: 'Perez' },
  { firstName: 'Noah', lastName: 'Sanchez' },
  { firstName: 'Harper', lastName: 'Ramirez' },
  { firstName: 'William', lastName: 'Torres' },
  { firstName: 'Avery', lastName: 'Flores' },
  { firstName: 'James', lastName: 'Gomez' },
  { firstName: 'Mia', lastName: 'Diaz' },
  { firstName: 'Benjamin', lastName: 'Hill' },
  { firstName: 'Evelyn', lastName: 'Rivera' },
  { firstName: 'Logan', lastName: 'Campbell' },
  { firstName: 'Abigail', lastName: 'Mitchell' },
  { firstName: 'Michael', lastName: 'Carter' },
  { firstName: 'Emily', lastName: 'Roberts' },
  { firstName: 'Alexander', lastName: 'Evans' },
  { firstName: 'Elizabeth', lastName: 'Collins' },
  { firstName: 'Daniel', lastName: 'Stewart' },
  { firstName: 'Sofia', lastName: 'Morris' },
  { firstName: 'Matthew', lastName: 'Murphy' },
  { firstName: 'Victoria', lastName: 'Cook' },
  { firstName: 'Jackson', lastName: 'Rogers' },
  { firstName: 'Luna', lastName: 'Peterson' },
  { firstName: 'David', lastName: 'Cooper' },
  { firstName: 'Chloe', lastName: 'Reed' },
  { firstName: 'Joseph', lastName: 'Bailey' },
  { firstName: 'Scarlett', lastName: 'Sanders' },
  { firstName: 'Carter', lastName: 'Bennett' },
  { firstName: 'Grace', lastName: 'Harrison' },
  { firstName: 'Wyatt', lastName: 'Price' },
  { firstName: 'Madison', lastName: 'Bell' },
  { firstName: 'Henry', lastName: 'Wood' },
  { firstName: 'Riley', lastName: 'Bryant' },
  { firstName: 'Aria', lastName: 'Reynolds' },
  { firstName: 'Sebastian', lastName: 'Jenkins' },
  { firstName: 'Ella', lastName: 'Fisher' },
  { firstName: 'Owen', lastName: 'Hunter' },
  { firstName: 'Aurora', lastName: 'Gordon' },
  { firstName: 'Gabriel', lastName: 'Sullivan' },
  { firstName: 'Ellie', lastName: 'Kennedy' },
  { firstName: 'Julian', lastName: 'Warren' },
  { firstName: 'Nora', lastName: 'Dixon' },
  { firstName: 'Lincoln', lastName: 'Rose' },
  { firstName: 'Hannah', lastName: 'Burns' },
  { firstName: 'Dylan', lastName: 'Craig' },
  { firstName: 'Leah', lastName: 'Parker' },
  { firstName: 'Mateo', lastName: 'Grant' },
  { firstName: 'Stella', lastName: 'Stone' },
  { firstName: 'Leo', lastName: 'Fox' },
  { firstName: 'Zoe', lastName: 'Mills' },
  { firstName: 'Asher', lastName: 'Wells' },
  { firstName: 'Claire', lastName: 'Henry' },
  { firstName: 'Luca', lastName: 'Ross' },
  { firstName: 'Emma', lastName: 'Newton' },
  { firstName: 'Adrian', lastName: 'Hudson' },
  { firstName: 'Aaliyah', lastName: 'Barnes' },
  { firstName: 'Xavier', lastName: 'Andrews' },
  { firstName: 'Ariana', lastName: 'Gibson' },
  { firstName: 'Nathan', lastName: 'Fuller' },
  { firstName: 'Penelope', lastName: 'Pearson' },
  { firstName: 'Samuel', lastName: 'Pierce' },
  { firstName: 'Isabelle', lastName: 'Spencer' },
  { firstName: 'Luke', lastName: 'Arnold' },
  { firstName: 'Maya', lastName: 'Black' },
  { firstName: 'Christopher', lastName: 'Holmes' },
  { firstName: 'Mila', lastName: 'Watson' },
  { firstName: 'Jack', lastName: 'Hawkins' },
  { firstName: 'Gianna', lastName: 'Ferguson' },
  { firstName: 'Caleb', lastName: 'Cunningham' },
  { firstName: 'Layla', lastName: 'Bradley' },
  { firstName: 'Henry', lastName: 'Knight' },
  { firstName: 'Eleanor', lastName: 'Lloyd' },
  { firstName: 'Ryan', lastName: 'Hart' },
  { firstName: 'Aubrey', lastName: 'Turner' },
  { firstName: 'John', lastName: 'West' },
  { firstName: 'Hazel', lastName: 'Richards' },
  { firstName: 'Julian', lastName: 'Wade' },
  { firstName: 'Lily', lastName: 'Johnston' },
  { firstName: 'Levi', lastName: 'Webb' },
  { firstName: 'Addison', lastName: 'Gregory' },
  { firstName: 'Elena', lastName: 'Walsh' },
  { firstName: 'Eli', lastName: 'Berry' },
  { firstName: 'Lillian', lastName: 'Gardner' },
  { firstName: 'Nathan', lastName: 'Sims' },
];

// Session types
export const addOnKey = 'add_on';
export const dropInKey = 'drop_in';
export const consultationKey = 'consultation';
export const seriesKey = 'series';
export const packageKey = 'package';
export const packagesLabel = 'Packages';
export const sessionTypeTypes = [
  addOnKey,
  dropInKey,
  consultationKey,
  seriesKey,
];
export const sessionTypeTypesMap = {
  [addOnKey]: 'Add-on',
  [dropInKey]: 'Drop-in',
  [consultationKey]: 'Consultation',
  [seriesKey]: 'Series',
  [packageKey]: 'Package',
};
export const sessionTypeTypesLabelMap = [
  { key: addOnKey, label: 'Add-on' },
  { key: consultationKey, label: 'Consultation' },
  { key: dropInKey, label: 'Normal drop-in service' },
  { key: seriesKey, label: 'Series' },
];
export const uncategorizedKey = 'uncategorized';

export const sessionUnitKey = 'session';
export const unitUnitKey = 'unit';
export const syringeUnitKey = 'syringe';
export const sessionTypeUnits = [sessionUnitKey, unitUnitKey, syringeUnitKey];
export const sessionTypeUnitsLabelMap = [
  { key: sessionUnitKey, label: 'Session' },
  { key: unitUnitKey, label: 'Unit' },
  { key: syringeUnitKey, label: 'Syringe' },
];
export const sessionTypeUnitsMap = {
  [sessionUnitKey]: 'Session',
  [unitUnitKey]: 'Unit',
  [syringeUnitKey]: 'Syringe',
};

export const directlyBookableSessionTypeStatus = 'direct';
export const requiresConsultationBookableSessionTypeStatus =
  'requires_consultation';
export const indirectlyBookableSessionTypeStatus = 'indirect';
export const unbookableSessionTypeStatus = 'unbookable';
export const requiresDepositSessionTypeStatus = 'requires_deposit';
export const discontinuedSessionTypeStatus = 'discontinued';
export const sessionTypeBookableStatuses = [
  directlyBookableSessionTypeStatus,
  requiresConsultationBookableSessionTypeStatus,
  indirectlyBookableSessionTypeStatus,
  unbookableSessionTypeStatus,
];
export const sessionTypeBookableStatusMap = {
  [directlyBookableSessionTypeStatus]:
    'Bookable directly via your LiveIQ agent',
  [indirectlyBookableSessionTypeStatus]:
    'Client must call the business to book',
  [requiresConsultationBookableSessionTypeStatus]:
    'Requires consultation before booking',
  [unbookableSessionTypeStatus]: 'Unbookable',
};
export const sessionTypeBookableStatusAbbreviatedLabelMap = {
  [directlyBookableSessionTypeStatus]: 'Bookable',
  [indirectlyBookableSessionTypeStatus]: 'Client must call',
  [requiresConsultationBookableSessionTypeStatus]: 'Requires consultation',
  [unbookableSessionTypeStatus]: 'Unbookable',
  [discontinuedSessionTypeStatus]: 'Unbookable',
};
export const sessionTypeBookableStatusLabelMap = [
  {
    key: directlyBookableSessionTypeStatus,
    label: 'Bookable directly via your LiveIQ agent',
  },
  {
    key: indirectlyBookableSessionTypeStatus,
    label: 'Client must call the business to book',
  },
  {
    key: requiresConsultationBookableSessionTypeStatus,
    label: 'Requires consultation before booking',
  },
  {
    key: unbookableSessionTypeStatus,
    label: 'Unbookable',
  },
];

// Sequence statuses
export const sequenceStagedStatusKey = 'staged';
export const sequenceStartedStatusKey = 'started';
export const sequenceSuccessfulStatusKey = 'successful';
export const sequenceFailedStatusKey = 'failed';
export const sequenceStatuses = [
  sequenceStagedStatusKey,
  sequenceStartedStatusKey,
  sequenceSuccessfulStatusKey,
  sequenceFailedStatusKey,
];
export const sequenceStatusLabelMap = {
  [sequenceStagedStatusKey]: 'Staged',
  [sequenceStartedStatusKey]: 'Started',
  [sequenceSuccessfulStatusKey]: 'Completed',
  [sequenceFailedStatusKey]: 'Failed',
};

// Sequence types
export const newChatsSequenceTypeKey = 'new_chats';
export const newChatFromTagSequenceTypeKey = 'new_chat_from_tag';
export const manuallyCreatedChatForContactSequenceTypeKey =
  'manually_created_chat_for_contact';
export const dripSequenceTypeKey = 'drip';
export const demoChatSequenceTypeKey = 'demo_chat';
export const sequenceTypeLabelMap = {
  [newChatsSequenceTypeKey]: 'New Chats',
  [dripSequenceTypeKey]: 'Drip',
  [demoChatSequenceTypeKey]: 'Demo Chat',
  [manuallyCreatedChatForContactSequenceTypeKey]: 'Manually Created Chat',
  [newChatFromTagSequenceTypeKey]: 'Ad Click',
};

// Timing
export const activeStartTimeKey = 'activeStartTime';
export const activeEndTimeKey = 'activeEndTime';
export const sequenceStartTimeKey = 'sequenceStartTime';
export const sequenceEndTimeKey = 'sequenceEndTime';

// Drip
export const responseFollowUpDripKey = 'response_follow_up';
export const firstUnresponsiveFollowUpDripKey = 'first_unresponsive_follow_up';
export const secondUnresponsiveFollowUpDripKey =
  'second_unresponsive_follow_up';
export const thirdUnresponsiveFollowUpDripKey = 'third_unresponsive_follow_up';
export const fourthUnresponsiveFollowUpDripKey =
  'fourth_unresponsive_follow_up';
export const followUpOptions = [
  {
    key: 0,
    label: 'No follow-ups',
  },
  {
    key: 1,
    label: '1 follow-up',
  },
  {
    key: 2,
    label: '2 follow-ups',
  },
  {
    key: 3,
    label: '3 follow-ups',
  },
  {
    key: 4,
    label: '4 follow-ups',
  },
  {
    key: 5,
    label: '5 follow-ups',
  },
  {
    key: 6,
    label: '6 follow-ups',
  },
  {
    key: 7,
    label: '7 follow-ups',
  },
];
export const dripCadenceOptions = [
  {
    key: 30,
    label: '30 minutes',
  },
  {
    key: 60,
    label: '1 hour',
  },
  {
    key: 120,
    label: '2 hours',
  },
  {
    key: 240,
    label: '4 hours',
  },
  {
    key: 480,
    label: '8 hours',
  },
  {
    key: 1440,
    label: '1 day',
  },
  {
    key: 2880,
    label: '2 days',
  },
  {
    key: 4320,
    label: '3 days',
  },
  {
    key: 5760,
    label: '4 days',
  },
  {
    key: 10080,
    label: '7 days',
  },
  {
    key: 14400,
    label: '10 days',
  },
  {
    key: 20160,
    label: '14 days',
  },
];

// Message template types
export const scriptMessageTemplateTypeKey = 'script';
export const sequenceStartMessageTemplateTypeKey = 'sequence_start';
export const sequenceFollowUpMessageTemplateTypeKey = 'sequence_follow_up';
export const messageTemplateTypes = [
  sequenceStartMessageTemplateTypeKey,
  sequenceFollowUpMessageTemplateTypeKey,
];

// Template types
export const singleNodeTemplateType = 'single_node';
export const scriptTemplateType = 'script';
export const templateTypes = [singleNodeTemplateType, scriptTemplateType];
export const templateTypeLabelMap = {
  [singleNodeTemplateType]: 'static template',
  [scriptTemplateType]: 'script',
};

// Scripts
export const messageScriptNodeType = 'message_node';
export const conditionalScriptNodeType = 'conditional_node';
export const bookingScriptNodeType = 'booking';
export const scriptNodeTypes = [
  messageScriptNodeType,
  conditionalScriptNodeType,
  bookingScriptNodeType,
];
export const nodeTypeLabelMap = {
  [messageScriptNodeType]: 'Message',
  [conditionalScriptNodeType]: 'Conditional',
  [bookingScriptNodeType]: 'Booking',
};
export const nodeTypes = [
  {
    key: messageScriptNodeType,
    label: 'Message Node',
  },
  {
    key: conditionalScriptNodeType,
    label: 'Conditional Node',
  },
  {
    key: bookingScriptNodeType,
    label: 'Booking Node',
  },
];

export const staticVariableType = 'static';
export const dynamicVariableType = 'dynamic';
export const contactNameMessageTemplateVariables = [
  {
    name: 'Contact first name',
    value: `contact.firstName`,
    type: staticVariableType,
  },
  {
    name: 'Contact last name',
    value: `contact.lastName`,
    type: staticVariableType,
  },
];
export const customMessageTemplateVariables = [
  ...contactNameMessageTemplateVariables,
  {
    name: 'Agent name',
    value: `agent.name`,
    type: staticVariableType,
  },
  {
    name: 'Business name',
    value: `business.name`,
    type: staticVariableType,
  },
];
export const messageTemplateVariableUnits = [
  {
    key: 'pounds',
    label: 'Pounds',
  },
  {
    key: 'inches',
    label: 'Inches',
  },
  {
    key: 'days',
    label: 'Days',
  },
  {
    key: 'months',
    label: 'Years',
  },
  {
    key: 'time_of_day',
    label: 'Time of Day',
  },
  {
    key: 'dollars',
    label: 'Dollars',
  },
];

// Modules
export const locationModuleKey = 'location';
export const catalogModuleKey = 'catalog';
export const policyModuleKey = 'policy';
export const agentSettingsModuleKey = 'agent_settings';

// Sub-modules
// Agent
export const agentInfoKey = 'agent_info';
export const deploymentKey = 'deployment';
export const messagingKey = 'messaging';
export const agentStyleKey = 'style';
// Overview
export const addressSubmoduleKey = 'address';
export const hoursSubmoduleKey = 'hours';
export const linksSubmoduleKey = 'links';
export const contactSubmoduleKey = 'contact';
export const overviewSubmodules = [
  addressSubmoduleKey,
  hoursSubmoduleKey,
  linksSubmoduleKey,
  contactSubmoduleKey,
];
// Catalog
export const staffSubmoduleKey = 'staff';
export const categoriesSubmoduleKey = 'corpus_categories';
export const offeringsSubmoduleKey = 'service_menu';
export const upsellsSubmoduleKey = 'upsells';
export const bookingFlowsSubmoduleKey = 'booking_flows';
export const serviceFaqsSubmoduleKey = 'service_FAQs';
export const generalFaqsSubmoduleKey = 'general_FAQs';
export const membershipsSubmoduleKey = 'memberships';
export const assetsSubmoduleKey = 'assets';
export const catalogSubmodules = [
  staffSubmoduleKey,
  categoriesSubmoduleKey,
  offeringsSubmoduleKey,
  upsellsSubmoduleKey,
  bookingFlowsSubmoduleKey,
  serviceFaqsSubmoduleKey,
  generalFaqsSubmoduleKey,
  membershipsSubmoduleKey,
  assetsSubmoduleKey,
];
// Policies
export const bookingSubmoduleKey = 'booking';
export const cancellationSubmoduleKey = 'cancellation';
export const followUpsSubmoduleKey = 'follow_ups';
export const paymentsSubmoduleKey = 'payments';
export const checkoutPageSubmoduleKey = 'checkout_page';
export const policySubmodules = [
  bookingSubmoduleKey,
  checkoutPageSubmoduleKey,
  cancellationSubmoduleKey,
  followUpsSubmoduleKey,
  paymentsSubmoduleKey,
];

// Agent settings
export const interactionStyleAgentSettingKey = 'interaction_style';
export const salesAgentSettingKey = 'sales';
export const availabilityAgentSettingKey = 'availability';
export const languageAgentSettingKey = 'language';

export const firstLinePhoneDeploymentSettingKey = 'first_line';
export const afterRingsPhoneDeploymentSettingKey = 'after_rings';
export const offHoursPhoneDeploymentSettingKey = 'off_hours';
export const phoneDeploymentSettingOptions = [
  {
    key: firstLinePhoneDeploymentSettingKey,
    label: 'First line of defence',
  },
  {
    key: afterRingsPhoneDeploymentSettingKey,
    label: 'After five unanswered rings',
  },
  {
    key: offHoursPhoneDeploymentSettingKey,
    label: 'Only off business hours',
  },
];

export const faqTaskKey = 'faqs';
export const createAppointmentTaskKey = 'createAppts';
export const rescheduleAppointmentTaskKey = 'rescheduleAppts';
export const cancelAppointmentTaskKey = 'cancelAppts';
export const updatePatientCharts = 'chartNotes';
export const membershipInquiryTaskKey = 'explainMemberships';
export const createMembershipTaskKey = 'createMemberships';
export const cancelMembershipTaskKey = 'cancelMemberships';
export const phoneTaskOptions = [
  { key: faqTaskKey, label: 'Answer FAQs' },
  { key: createAppointmentTaskKey, label: 'Book appointments' },
  { key: rescheduleAppointmentTaskKey, label: 'Reschedule appointments' },
  { key: cancelAppointmentTaskKey, label: 'Cancel appointments' },
  { key: updatePatientCharts, label: 'Add notes to patient charts' },
  { key: membershipInquiryTaskKey, label: 'Explain membership plans' },
  { key: createMembershipTaskKey, label: 'Create a membership' },
  { key: cancelMembershipTaskKey, label: 'Cancel a membership' },
];

// Services
// Category types
export const serviceCategoryTypeKey = 'service';
export const generalCategoryTypeKey = 'general';
export const policyCategoryTypeKey = 'policy';

// Service categories
export const bodyContouringCategoryKey = 'body_contouring';
export const cryoCategoryKey = 'cryo';
export const facialsCategoryKey = 'facials';
export const fertilityCategoryKey = 'fertility';
export const fillersCategoryKey = 'fillers';
export const hairRestorationCategoryKey = 'hair_restoration';
export const hormoneCategoryKey = 'hormone';
export const ivCategoryKey = 'IV';
export const lashAndBrowCategoryKey = 'lash_&_brow';
export const massagesCategoryKey = 'massages';
export const neurotoxinsCategoryKey = 'neurotoxins';
export const reproductiveHealthCategoryKey = 'reproductive_health';
export const skinAndLaserCategoryKey = 'skin_&_laser';
export const waxingCategoryKey = 'waxing';
export const weightLossCategoryKey = 'weight_loss';

export const allServiceCategories = [
  bodyContouringCategoryKey,
  cryoCategoryKey,
  facialsCategoryKey,
  fertilityCategoryKey,
  fillersCategoryKey,
  hairRestorationCategoryKey,
  hormoneCategoryKey,
  ivCategoryKey,
  lashAndBrowCategoryKey,
  massagesCategoryKey,
  neurotoxinsCategoryKey,
  reproductiveHealthCategoryKey,
  skinAndLaserCategoryKey,
  waxingCategoryKey,
  weightLossCategoryKey,
];
export const serviceCategoryOptions = allServiceCategories.map((c) => {
  return {
    key: c,
    label: formatKeyToLabel(c),
  };
});

// Prompt types
export const bookingPromptTypeKey = 'booking';
export const faqPromptTypeKey = 'faq';
// Prompt keys
export const universalPromptKey = 'universal';
export const locationCustomPromptKey = 'location_custom';

// Service prompts
export const defaultServicePromptKey = 'default';
export const defaultMultipersonServicePromptKey = 'default_multiperson';

// Resolution types
// Booking resolutions
export const directMappingKey = 'direct';
export const clarifyOptionsMappingKey = 'clarify_options';
export const upsellAdditionalItemsMappingKey = 'upsell_additional_items';
export const deferMappingKey = 'defer';
export const alternativeSuggestionMappingKey = 'alternative_suggestion';
export const notApplicableMappingKey = 'not_applicable';
export const optionsMap = {
  [directMappingKey]: {
    label: 'Book one service',
    subtitle: 'Recommend a specific service and proceed with booking',
    description: 'Proceed with booking a specific service',
  },
  [clarifyOptionsMappingKey]: {
    label: 'Clarify options',
    subtitle: 'Present multiple options for the client to select from',
    description: 'Suggest booking one of multiple options',
  },
  [alternativeSuggestionMappingKey]: {
    label: 'Suggest alternative',
    subtitle: `Recommend an alternative offering in lieu of what they're asking for`,
    description: 'Suggest an alternative offering',
  },
  [deferMappingKey]: {
    label: 'Defer to a human',
    subtitle: `Recommend they speak to a human representative`,
    description: 'Defer to a human',
  },
  [notApplicableMappingKey]: {
    label: 'Decline',
    subtitle: `Tell the client that your business doesn't offer what they're asking for`,
    description: 'Inform the client that this is not offered',
  },
};

// FAQ resolutions
export const customAnswerResolutionKey = 'custom_answer';
export const deferredAnswerResolutionKey = 'deferred_answer';

// Payment types
export const cashPaymentTypeKey = 'cash';
export const debitPaymentTypeKey = 'debit';
export const creditPaymentTypeKey = 'credit';
export const spaFinderPaymentTypeKey = 'spa_finder';
export const allePaymentTypeKey = 'alle';
export const careCreditPaymentTypeKey = 'care_credit';
export const cherryPaymentTypeKey = 'cherry';
export const insurancePaymentTypeKey = 'insurance';
export const hsaFsaPaymentTypeKey = 'hsa_fsa';
export const paymentTypeOptions = [
  {
    key: allePaymentTypeKey,
    label: 'Alle Payment Plans',
  },
  {
    key: careCreditPaymentTypeKey,
    label: 'Care Credit',
  },
  {
    key: cashPaymentTypeKey,
    label: 'Cash',
  },
  {
    key: cherryPaymentTypeKey,
    label: 'Cherry Payment Plans',
  },
  {
    key: creditPaymentTypeKey,
    label: 'Credit',
  },
  {
    key: debitPaymentTypeKey,
    label: 'Debit',
  },
  {
    key: hsaFsaPaymentTypeKey,
    label: 'HSA/FSA',
  },
  {
    key: insurancePaymentTypeKey,
    label: 'Insurance',
  },
  {
    key: spaFinderPaymentTypeKey,
    label: 'Spa Finder Gift Cards',
  },
];

// Contact event options
export const appointmentBookedContactEventKey =
  'appointment_booked_contact_event';
export const appointmentRescheduledContactEventKey =
  'appointment_rescheduled_contact_event';
export const appointmentCancelledContactEventKey =
  'appointment_cancelled_contact_event';
export const callbackRequestedContactEventKey =
  'callback_requested_contact_event';

export default {
  services,
  clinicSoftware,
  timeframes,
  leadSources,
  internalLeadSources,
  integerInputType,
  floatInputType,
  percentInputType,
  dollarInputType,
  textInputType,
  arrayInputType,
  urlInputType,
  numericInputTypes,
  dropdownInputType,
  phoneInputType,
  userRoles,
  valueGoalKey,
  sellGoalKey,
  buyGoalKey,
  growGoalKey,
  understandGoalKey,
  userGoals,
  descendingSortDirection,
  ascendingSortDirection,
  asapTimeframeKey,
  getTaskStatusChipText,
  listingFlowKey,
  graphqlTypenameFieldKey,
  transitionPeriods,
  balanceSheetFileType,
  incomeStatementFileType,
  quickbooksKey,
  mindBodyKey,
  ghlKey,
  appNameLabelMap,
  integrations,
  inProgressStatusKey,
  saleTypeLabels,
  serviceCategoryLabels,
  campaignLeadConversionLabels,
  campaignLtvToCacLabels,
  leadSourceLabels,
};

import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useRef, useState } from 'react';
import {
  useMessageTemplateContent,
  useMyFiles,
} from '../../../api/hooks/content';
import { useAvailableSessionTypes } from '../../../api/hooks/enterprise';
import {
  CREATE_MESSAGE_TEMPLATE,
  EDIT_MESSAGE_TEMPLATE,
} from '../../../api/mutations/content';
import {
  CenteredDiv,
  Checkbox,
  Chip,
  ChipCancelIcon,
  DarkMLText,
  Dropdown,
  DropdownLabel,
  EmptyGapColumnCenteredDiv,
  Form,
  FormControlLabel,
  FormGroup,
  FullWidthStartAlignedColumnDiv,
  LightTinyText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  allKey,
  contactNameMessageTemplateVariables,
  customMessageTemplateVariables,
  firstUnresponsiveFollowUpDripKey,
  secondUnresponsiveFollowUpDripKey,
  sequenceStartMessageTemplateTypeKey,
  thirdUnresponsiveFollowUpDripKey,
  unclassifiedKey,
} from '../../../utils/constants';
import { removeItemObject } from '../../../utils/data';
import { scrollElementIntoView } from '../../../utils/view';
import { VariablesInputLabel } from '../../Form/CustomVariablesMenu/styled';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import PopperMenu from '../../PopperMenu';
import {
  AttachFileText,
  AttachedFileContainer,
  AttachedFilesContainer,
  AttachmentsContainer,
  FilesDescriptionContainer,
  RemoveAttachedFileIcon,
} from './styled';

const allServicesKey = 'all_services';
const allServicesLabel = 'All Services';

const openingMessageTabKey = 0;
const firstFollowUpMessageTabKey = 1;
const secondFollowUpMessageTabKey = 2;
const thirdFollowUpMessageTabKey = 3;

const tabContentTypeMap = {
  [openingMessageTabKey]: sequenceStartMessageTemplateTypeKey,
  [firstFollowUpMessageTabKey]: firstUnresponsiveFollowUpDripKey,
  [secondFollowUpMessageTabKey]: secondUnresponsiveFollowUpDripKey,
  [thirdFollowUpMessageTabKey]: thirdUnresponsiveFollowUpDripKey,
};

const defaultContentObject = {
  [sequenceStartMessageTemplateTypeKey]: {
    text: '',
    autoGenerateText: false,
  },
  [firstUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: firstUnresponsiveFollowUpDripKey,
  },
  [secondUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: secondUnresponsiveFollowUpDripKey,
  },
  [thirdUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: thirdUnresponsiveFollowUpDripKey,
  },
};

const formatContent = (content) => {
  if (!content) {
    return defaultContentObject;
  }

  const contentMap = {};

  content?.map((c) => {
    contentMap[c.type] = c;
  });

  return contentMap;
};

const fieldsContainerId = 'fields-container';

const SingleNodeTemplateModal = ({
  id,
  campaignId,
  isOpen,
  onClose,
  onSave,
}) => {
  const { data, loading, refetch } = useMessageTemplateContent({
    id,
    skipCondition: !isOpen,
  });
  const template = data?.template || {};
  const content = formatContent(data?.content);

  const [
    createMessageTemplateMutation,
    { loading: createMessageTemplateLoading },
  ] = useMutation(CREATE_MESSAGE_TEMPLATE);
  const [editMessageTemplateMutation, { loading: editMessageTemplateLoading }] =
    useMutation(EDIT_MESSAGE_TEMPLATE);
  const { sessionTypes, loading: sessionTypesLoading } =
    useAvailableSessionTypes({ asCatalog: true });
  const { files, loading: filesLoading } = useMyFiles();

  const inputRef = useRef();

  const [name, setName] = useState(template?.name || '');
  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [attachFileMenuAnchorEl, setAttachFileMenuAnchorEl] = useState();
  const [contentToEdit, setContentToEdit] = useState(content);
  const [tab, setTab] = useState(openingMessageTabKey);

  const attachFileMenuOpen = Boolean(attachFileMenuAnchorEl);

  useEffect(() => {
    if (data) {
      const template = data?.template || {};
      setName(template.name || '');

      const content = formatContent(data?.content);
      setContentToEdit(content);
    }
  }, [data]);

  useEffect(() => {
    if (sessionTypes) {
      const values = Object.values(sessionTypes);
      setAvailableSessionTypes(
        values.map((s) => {
          return {
            categoryId: s.id,
            categoryName: s.name,
            services: s.dropIns,
          };
        }),
      );
    }
  }, [sessionTypes]);

  const handleClose = () => {
    setName('');
    setContentToEdit(defaultContentObject);
    setTab(openingMessageTabKey);
    onClose();
  };

  const onUpdateSessionTypes = (
    currentMentionedSessionTypes,
    checked,
    id,
    name,
    isCategory,
    isAllKey = false,
    categoryId = null,
  ) => {
    let newServices;

    if (checked) {
      const object = {
        id,
        categoryId,
        isCategory,
        isAllKey,
        name,
      };
      if (isAllKey) {
        newServices = { [id]: object };
      } else if (isCategory) {
        delete currentMentionedSessionTypes[allKey];
        newServices = {
          ...currentMentionedSessionTypes,
          [id]: object,
        };
        newServices = Object.fromEntries(
          Object.entries({ ...newServices }).filter(
            ([_, value]) =>
              value.categoryId !== id &&
              !(value.categoryId == null && value.isCategory == null),
          ),
        );
      } else {
        delete currentMentionedSessionTypes[allKey];
        newServices = { ...currentMentionedSessionTypes };
        newServices[id] = object;
      }
    } else {
      newServices = removeItemObject(currentMentionedSessionTypes, id);
    }

    return newServices;
  };

  const onUpdateContent = (field, value) => {
    const updatedContent = { ...contentToEdit };

    updatedContent[typeToDisplay] = {
      ...updatedContent[typeToDisplay],
      [field]: field === 'services' ? Object.values(value) : value,
      type: typeToDisplay,
    };
    if (field === 'files' && !value?.length) {
      updatedContent[typeToDisplay] = {
        ...updatedContent[typeToDisplay],
        filesDescription: '',
      };
    }

    setContentToEdit(updatedContent);
  };

  const onSuccess = async () => {
    handleClose();
    await onSave();
  };

  const handleSave = () => {
    const contentObjects = Object.values(contentToEdit);

    const formattedContentObjects = contentObjects.map((c) => {
      const services = c.services || {};

      const formattedServices = Object.keys(services)
        .map((key) => {
          const service = services[key];
          const { id, categoryId, isCategory } = service;

          if (id === allServicesKey) {
            return null;
          } else if (isCategory) {
            return {
              sessionTypeId: null,
              categoryId: id,
            };
          } else {
            return { sessionTypeId: id, categoryId };
          }
        })
        .filter((s) => s != null);

      const fileIds = c?.files?.map((f) => f.id) || [];

      return {
        ...c,
        services: formattedServices,
        fileIds,
        files: undefined,
      };
    });

    if (id) {
      editMessageTemplateMutation({
        variables: {
          id: template.id,
          name,
          content: formattedContentObjects,
        },
        onCompleted: async (data) => {
          const success = data.editMessageTemplate;

          if (success) {
            await onSuccess();
          }
        },
      });
    } else {
      createMessageTemplateMutation({
        variables: {
          name,
          content: formattedContentObjects,
          campaignId,
        },
        onCompleted: async (data) => {
          const success = data.createMessageTemplate;

          if (success) {
            await onSuccess();
          }
        },
      });
    }
  };

  const scrollToBottom = () => {
    scrollElementIntoView(
      document,
      fieldsContainerId,
      'end',
      'nearest',
      'instant',
    );
  };

  if (
    editMessageTemplateLoading ||
    loading ||
    sessionTypesLoading ||
    filesLoading
  ) {
    return <LoadingIndicator fullScreen />;
  }

  const saveEnabled = !!name;

  const typeToDisplay = tabContentTypeMap[tab];
  const contentToDisplay = contentToEdit[typeToDisplay];

  const services = contentToDisplay?.services || [];
  let displayedMentionedSessionTypes = {};
  if (services.length) {
    services.map((s) => {
      const id = s.id;
      displayedMentionedSessionTypes[id] = s;
    });
  } else {
    displayedMentionedSessionTypes = {};
  }
  const mentionedSessionTypesArray =
    Object.values(displayedMentionedSessionTypes) || [];
  const allServicesSelected = allServicesKey in displayedMentionedSessionTypes;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <DarkMLText>{id ? 'Edit' : 'Create'} Message Template</DarkMLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper
            columnFlex
            largeGap
          >
            <MediumGapColumnCenteredDiv
              topMargin={10}
              id={fieldsContainerId}
            >
              <Input
                id='name'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                label='Template name'
                isRequired={false}
                removeGap
              />
              <EmptyGapColumnCenteredDiv>
                <CenteredDiv>
                  <Tabs
                    value={tab}
                    onChange={(_, key) => setTab(key)}
                    addBottomMargin
                  >
                    <Tab
                      value={openingMessageTabKey}
                      label='Opener'
                    />
                    <Tab
                      value={firstFollowUpMessageTabKey}
                      label='Follow-Up 1'
                    />
                    <Tab
                      value={secondFollowUpMessageTabKey}
                      label='Follow-Up 2'
                    />
                    <Tab
                      value={thirdFollowUpMessageTabKey}
                      label='Follow-Up 3'
                    />
                  </Tabs>
                </CenteredDiv>
                {typeToDisplay === sequenceStartMessageTemplateTypeKey ? (
                  <Input
                    id='text'
                    value={contentToDisplay?.text}
                    onChange={(e) => {
                      onUpdateContent('text', e.target.value);
                    }}
                    label={<VariablesInputLabel>Content</VariablesInputLabel>}
                    isRequired={false}
                    multiline
                    numRows={7}
                    variables={customMessageTemplateVariables}
                    removeGap
                    inputRef={inputRef}
                  />
                ) : (
                  <Input
                    id='text'
                    value={contentToDisplay?.text}
                    onChange={(e) => {
                      onUpdateContent('text', e.target.value);
                    }}
                    label={<VariablesInputLabel>Content</VariablesInputLabel>}
                    isRequired={false}
                    multiline
                    numRows={7}
                    variables={contactNameMessageTemplateVariables}
                    showAutoGenerateToggle
                    autoGenerate={!!contentToDisplay?.autoGenerateText}
                    onChangeAutoGenerate={(updatedAutoGenerate) => {
                      onUpdateContent('autoGenerateText', updatedAutoGenerate);
                    }}
                    removeGap
                    inputRef={inputRef}
                  />
                )}
              </EmptyGapColumnCenteredDiv>
              <Input
                id='services'
                value={mentionedSessionTypesArray}
                removeGap={true}
                CustomInputComponent={
                  <Form fitContentHeight>
                    <DropdownLabel>
                      Specific services mentioned (leave blank if none)
                    </DropdownLabel>
                    <Dropdown
                      multiple
                      label='Specific services mentioned (leave blank if none)'
                      value={mentionedSessionTypesArray}
                      renderValue={(selected) => (
                        <Stack>
                          {Object.values(selected)?.map(
                            (selectedServiceObject) => {
                              const { id, name, isCategory, isAllKey } =
                                selectedServiceObject;

                              const categoryLabel = isCategory
                                ? `All ${name} Services`
                                : null;

                              const formattedLabel = isAllKey
                                ? allServicesLabel
                                : isCategory
                                ? categoryLabel
                                : name?.length > 26
                                ? `${name.slice(0, 23)}...`
                                : name;

                              const isHeader = isCategory || isAllKey;

                              return (
                                <Chip
                                  key={id}
                                  secondary={isHeader}
                                  ternary={!isHeader}
                                  label={formattedLabel}
                                  onDelete={() => {
                                    const mentionedSessionTypesAfterRemoval =
                                      removeItemObject(
                                        displayedMentionedSessionTypes,
                                        id,
                                      );
                                    onUpdateContent(
                                      'services',
                                      mentionedSessionTypesAfterRemoval,
                                    );
                                  }}
                                  deleteIcon={
                                    <ChipCancelIcon
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              );
                            },
                          )}
                        </Stack>
                      )}
                    >
                      <FormGroup
                        key={allServicesKey}
                        smallBottomMargin={true}
                      >
                        <FormControlLabel
                          isLabel
                          isTitle
                          control={
                            <Checkbox
                              checked={allServicesSelected}
                              onClick={(e) => {
                                const updatedSessionTypes =
                                  onUpdateSessionTypes(
                                    displayedMentionedSessionTypes,
                                    e.target.checked,
                                    allServicesKey,
                                    allServicesLabel,
                                    false,
                                    true,
                                  );
                                onUpdateContent(
                                  'services',
                                  updatedSessionTypes,
                                );
                              }}
                            />
                          }
                          label={allServicesLabel}
                        />
                      </FormGroup>
                      {!allServicesSelected &&
                        sortBy(availableSessionTypes, (s) =>
                          s.categoryName === unclassifiedKey
                            ? 'zz'
                            : s.categoryName,
                        )?.map((sessionTypeCategory) => {
                          const { categoryId, categoryName, services } =
                            sessionTypeCategory;
                          const categoryLabel = `${categoryName} Services`;
                          const wholeCategorySelected =
                            categoryId in displayedMentionedSessionTypes;
                          return (
                            <>
                              <FormGroup
                                key={categoryId}
                                smallBottomMargin={wholeCategorySelected}
                              >
                                <FormControlLabel
                                  isLabel
                                  isTitle
                                  control={
                                    <Checkbox
                                      checked={wholeCategorySelected}
                                      onClick={(e) => {
                                        const updatedSessionTypes =
                                          onUpdateSessionTypes(
                                            displayedMentionedSessionTypes,
                                            e.target.checked,
                                            categoryId,
                                            categoryName,
                                            true,
                                          );
                                        onUpdateContent(
                                          'services',
                                          updatedSessionTypes,
                                        );
                                      }}
                                    />
                                  }
                                  label={categoryLabel}
                                />
                              </FormGroup>
                              {!wholeCategorySelected &&
                                sortBy(services, (s) => s.displayName).map(
                                  (s, idx) => (
                                    <FormGroup
                                      key={s.id}
                                      largeBottomMargin={
                                        idx === services?.length - 1
                                      }
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              wholeCategorySelected ||
                                              s.id in
                                                displayedMentionedSessionTypes
                                            }
                                            onClick={(e) => {
                                              const updatedSessionTypes =
                                                onUpdateSessionTypes(
                                                  displayedMentionedSessionTypes,
                                                  e.target.checked,
                                                  s.id,
                                                  s.displayName,
                                                  false,
                                                  false,
                                                  categoryId,
                                                );
                                              onUpdateContent(
                                                'services',
                                                updatedSessionTypes,
                                              );
                                            }}
                                          />
                                        }
                                        label={s.displayName}
                                      />
                                    </FormGroup>
                                  ),
                                )}
                            </>
                          );
                        })}
                    </Dropdown>
                  </Form>
                }
              />
              <FullWidthStartAlignedColumnDiv>
                {files?.length ? (
                  <AttachmentsContainer>
                    <AttachFileText
                      onClick={(e) =>
                        setAttachFileMenuAnchorEl(e.currentTarget)
                      }
                    >
                      Attach files
                    </AttachFileText>
                    <AttachedFilesContainer>
                      {contentToDisplay?.files?.map((file) => (
                        <AttachedFileContainer>
                          <LightTinyText>{file.name}</LightTinyText>
                          <RemoveAttachedFileIcon
                            onClick={() => {
                              const updatedFiles = [
                                ...contentToDisplay?.files?.filter(
                                  (f) => f.id !== file.id,
                                ),
                              ];
                              onUpdateContent('files', updatedFiles);
                            }}
                          />
                        </AttachedFileContainer>
                      ))}
                    </AttachedFilesContainer>
                    {contentToDisplay?.files?.length ? (
                      <FilesDescriptionContainer>
                        <Input
                          id='files-description'
                          value={contentToDisplay?.filesDescription}
                          onChange={(e) => {
                            onUpdateContent('filesDescription', e.target.value);
                            scrollToBottom();
                          }}
                          label='Attachments description'
                          removeGap
                        />
                      </FilesDescriptionContainer>
                    ) : (
                      <></>
                    )}
                  </AttachmentsContainer>
                ) : (
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        Upload files in order to attach them in templates
                      </TooltipTitleText>
                    }
                  >
                    <AttachFileText disabled>Attach files</AttachFileText>
                  </Tooltip>
                )}
              </FullWidthStartAlignedColumnDiv>
              <PopperMenu
                open={attachFileMenuOpen}
                anchorElement={attachFileMenuAnchorEl}
                onClose={() => setAttachFileMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select file to attach
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {files.map((file) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        onUpdateContent('files', [
                          ...(contentToDisplay?.files || []),
                          file,
                        ]);
                        setAttachFileMenuAnchorEl();
                      }}
                    >
                      <SmallMenuItemText>{file.name}</SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
            </MediumGapColumnCenteredDiv>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          <MediumPrimaryButton
            disabled={!saveEnabled}
            onClick={handleSave}
          >
            Save
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={handleClose}>
            Close
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default SingleNodeTemplateModal;

import styled from 'styled-components';
import {
  BackIcon,
  CenterFlexStyle,
  ColumnDiv,
  ColumnFlexStyle,
  DarkLargeText,
  EssGapStyle,
  EssText,
  SMGapStyle,
  SMText,
  SecondaryPrimaryButton,
  SmallGapStyle,
  SmallIconSize,
  UnderlinedTextStyle,
} from '../../styles/shared-styled-components';

export const TopContainer = styled(ColumnDiv)`
  ${SMGapStyle}
`;

export const BackTopStripContainer = styled.div`
  display: flex;
  height: 20px;
  position: absolute;
  left: 10px;
  top: ${({ theme }) =>
    theme.spacing.headerHeight +
    theme.spacing.headerVerticalPadding * 2 +
    15}px;
`;

export const SectionTopStripContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  height: 30px;
  left: 10px;
  position: absolute;
  top: ${({ theme }) =>
    theme.spacing.headerHeight +
    theme.spacing.headerVerticalPadding * 2 +
    15}px;
`;

export const BackTopStripIcon = styled(BackIcon)`
  ${SmallIconSize}
`;

export const SectionTopStripText = styled(EssText)`
  text-align: start;
  ${UnderlinedTextStyle}
  display: flex;
  ${CenterFlexStyle}
  gap: 1px;
  // color: ${({ theme }) => theme.color.lightSecondaryAccent};
  font-size: 19px;
`;

export const SectionTopStripButton = styled(SecondaryPrimaryButton)`
  font-size: 19px;
  min-width: clamp(120px, 36vw, 180px);
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  background-color: ${({ theme }) => theme.color.lightSecondaryAccent};
  padding: 0px;
  margin: 0px;
  display: flex;
  ${CenterFlexStyle}
  ${EssGapStyle}
  &:hover {
    color: white;
  }
`;

export const HeaderTextContainer = styled.div`
  ${ColumnFlexStyle}
  ${SmallGapStyle}
  margin-top: ${(props) => props.addTopMargin && '40px'};
`;

export const FormTitleText = styled(DarkLargeText)``;

export const FormSubtitleText = styled(SMText)`
  color: ${({ theme }) => theme.color.muiInputDarkGray};
`;

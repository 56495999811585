import React, { useContext, useEffect, useState } from 'react';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { getTypesenseClient } from '../../../../api/typesense/typesense-client';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { InfoIcon } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import {
  ColumnCenteredDiv,
  ContentContainer,
  EndAlignedFlexDiv,
  LightDarkTinyText,
  MediumNoDataIcon,
  PageContainer,
  PageTitleText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkTinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  allKey,
  ascendingKey,
  campaignForPipelineCookieKey,
  clientContactType,
  contactedCampaignMemberStatusKey,
  contactNameForPipelineCookieKey,
  contactTypeForPipelineCookieKey,
  contactTypesArr,
  contactTypesForCampaigns,
  declinedCampaignMemberStatusKey,
  emptyIntValue,
  firstNameField,
  intraStringSeparatorChar,
  leadContactType,
  optedOutCampaignMemberStatusKey,
  sequenceCompletedCampaignMemberStatusKey,
  stagingFilterConversionDelay,
  staleCampaignMemberStatusKey,
  successfullyBookedCampaignMemberStatusKey,
  textSeparatorChar,
  typesenseContactSchemaName,
  uncontactedCampaignMemberStatusKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject, getTimestamp } from '../../../../utils/date';
import {
  getSortParam,
  getTypesenseFilterString,
} from '../../../../utils/filters';
import { getFormattedFullNameFromUser } from '../../../../utils/name';
import { formatNumber } from '../../../../utils/numbers';
import {
  getUserLocationId,
  getUserTypesenseApiKey,
} from '../../../../utils/user';
import {
  LoadMoreIcon,
  PipelineClient,
  PipelineClientContacted,
  PipelineClientContainer,
  PipelineClientName,
  PipelineColumn,
  PipelineColumnHeader,
  PipelineColumnHeaderSubtext,
  PipelineColumnHeaderTitle,
  PipelineContainer,
  PipelineFiltersContainer,
  ResetFiltersIcon,
  SearchIconContainer,
} from './styled';

const testClients = [
  {
    name: 'Priyanka lal',
    spending: 1000,
    lastContacted: '',
    status: 'client',
  },
  {
    name: 'Eric James',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'client',
  },
  {
    name: 'James White',
    spending: 1000,
    lastContacted: '',
    status: 'client',
  },
  {
    name: 'Harry',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'lead',
  },
  {
    name: 'L. James',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Jeff Luk',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'client',
  },
  {
    name: 'Barrack O.',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Yusuf Khaled',
    spending: 1000,
    lastContacted: '',
    status: 'member',
  },
  {
    name: 'Donald T.',
    spending: 1000,
    lastContacted: '',
    status: 'member',
  },
  {
    name: 'Dwayne J.',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Eric Green',
    spending: 1000,
    lastContacted: '2021-10-10',
    lastVisit: '2023-10-08',
    status: 'member',
  },
  {
    name: 'Chris Johnson',
    spending: 1000,
    lastContacted: '2021-10-10',
    lastVisit: '2022-10-08',
    status: 'member',
  },
];

const getCampaignIdsString = (ids) => {
  return (ids || []).sort().join(',');
};

const sortParam = getSortParam(firstNameField, ascendingKey);

const pageSize = 200;

const getContactSearchParameters = (
  locationId,
  campaignId,
  selectedContactType,
  searchFilter = '',
  pageNumber = 1,
) => {
  let filterString = getTypesenseFilterString([locationId], {});

  let searchParameters = {
    per_page: pageSize,
    page: pageNumber,
    sort_by: sortParam,
  };

  if (selectedContactType !== allKey) {
    filterString += ` && type:=[${selectedContactType}]`;
    searchParameters['filter_by'] = filterString;
  }

  if (searchFilter) {
    filterString += ` && campaignIds:=[${campaignId}]`;

    searchParameters = {
      ...searchParameters,
      q: searchFilter,
      query_by: 'firstName,lastName',
      filter_by: filterString,
    };
  } else {
    searchParameters = {
      ...searchParameters,
      q: campaignId,
      query_by: 'campaigns',
    };
  }

  return searchParameters;
};

const expiry = getCookieExpiryObject();

const maxNameChars = 20;

const statusLabelMap = {
  [uncontactedCampaignMemberStatusKey]: {
    label: 'Uncontacted',
    description:
      'Enrolled in the campaign, but have not yet been reached out to',
  },
  [contactedCampaignMemberStatusKey]: {
    label: 'Contacting',
    description:
      'Been contacted about the campaign, and are actively in discussion or being followed up with',
  },
  [successfullyBookedCampaignMemberStatusKey]: {
    label: 'Successfully Booked',
    description:
      'Received the campaign outreach, and successfully booked an appointment because of it',
  },
  [staleCampaignMemberStatusKey]: {
    label: 'Follow-Ups Complete',
    description: `Have received all follow-ups for the campaign, but still haven't booked an appointment`,
  },
  [declinedCampaignMemberStatusKey]: {
    label: 'Declined',
    description: `Politely declined the campaign outreach, but haven't unsubscribed from marketing communications`,
  },
  [optedOutCampaignMemberStatusKey]: {
    label: 'Unsubscribed',
    description:
      'Declined the campaign outreach, and also unsubscribed from all marketing communications',
  },
};

const Pipeline = () => {
  const { user, drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({});

  useEffect(() => {
    refetchCampaigns();
  }, [refetchCampaigns]);

  const allCampaignIds = [];
  const campaignNameMap = {};
  const campaignOptions = (campaigns || []).map((c) => {
    const { id, name } = c;
    allCampaignIds.push(id);
    const formattedName =
      name.length > maxNameChars ? `${name.slice(0, maxNameChars)}...` : name;
    campaignNameMap[id] = formattedName;
    return { key: c.id, label: formattedName };
  });
  const campaignIdsString = getCampaignIdsString(allCampaignIds);

  const storedNameCookie = cookies[contactNameForPipelineCookieKey];
  const storedCampaignCookie = cookies[campaignForPipelineCookieKey];
  const storedContactTypeCookie = cookies[contactTypeForPipelineCookieKey];

  const [searchFilter, setSearchFilter] = useState(storedNameCookie);
  const [stagingSearchFilter, setStagingSearchFilter] =
    useState(storedNameCookie);
  const [selectedCampaignIdArr, setSelectedCampaignIdArr] = useState(
    storedCampaignCookie
      ? [storedCampaignCookie]
      : allCampaignIds?.length
      ? [allCampaignIds[0]]
      : [],
  );
  const [selectedContactTypes, setSelectedContactTypes] = useState(
    storedContactTypeCookie ? [storedContactTypeCookie] : contactTypesArr,
  );
  const [contacts, setContacts] = useState([]);
  const [numResults, setNumResults] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const selectedCampaignId = selectedCampaignIdArr?.[0];
  const selectedContactType = selectedContactTypes?.[0];

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setCookie(contactNameForPipelineCookieKey, stagingSearchFilter, expiry);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    setStagingSearchFilter(storedNameCookie);
    setSearchFilter(storedNameCookie);
  }, [storedNameCookie]);

  useEffect(() => {
    setSelectedCampaignIdArr(
      storedCampaignCookie
        ? [storedCampaignCookie]
        : allCampaignIds?.length
        ? [allCampaignIds[0]]
        : [],
    );
  }, [storedCampaignCookie, campaignIdsString]);

  useEffect(() => {
    setSelectedContactTypes(
      storedContactTypeCookie ? [storedContactTypeCookie] : contactTypesArr,
    );
  }, [storedContactTypeCookie]);

  useEffect(() => {
    async function fetchClients() {
      if (selectedCampaignId) {
        const searchParameters = getContactSearchParameters(
          locationId,
          selectedCampaignId,
          selectedContactType,
          searchFilter,
          pageNumber,
        );

        const data = await typesenseClient
          .collections(typesenseContactSchemaName)
          .documents()
          .search(searchParameters);
        const documents = data.hits.map((hit) => hit.document);

        setContacts(documents);
        setNumResults(data.found);
        setDataLoading(false);
        setPageNumber(1);
      }
    }

    fetchClients();
  }, [selectedCampaignId, selectedContactType, searchFilter]);

  useEffect(() => {
    async function fetchClients() {
      if (selectedCampaignId) {
        const searchParameters = getContactSearchParameters(
          locationId,
          selectedCampaignId,
          selectedContactType,
          searchFilter,
          pageNumber,
        );

        const data = await typesenseClient
          .collections(typesenseContactSchemaName)
          .documents()
          .search(searchParameters);
        const documents = data.hits.map((hit) => hit.document);

        setContacts([...contacts, ...documents]);
        setNumResults(data.found);
        setDataLoading(false);
      }
    }

    fetchClients();
  }, [pageNumber]);

  const hasFilters =
    !campaignsLoading && !!selectedContactType && !!selectedCampaignId;

  const contactMap = {
    [uncontactedCampaignMemberStatusKey]: [],
    [contactedCampaignMemberStatusKey]: [],
    [successfullyBookedCampaignMemberStatusKey]: [],
    [staleCampaignMemberStatusKey]: [],
    [declinedCampaignMemberStatusKey]: [],
    [optedOutCampaignMemberStatusKey]: [],
  };
  contacts.map((c) => {
    const campaignIds = c.campaigns;
    const relevantCampaignId = campaignIds.find((id) =>
      id.includes(selectedCampaignId),
    );
    if (relevantCampaignId) {
      const status = relevantCampaignId.split(intraStringSeparatorChar)[1];
      const contactsForStatus = contactMap[status] || [];
      contactMap[status] = [...contactsForStatus, c];
    }
  });
  const laneKeys = Object.keys(contactMap);

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setStagingSearchFilter(stagingSearchFilterValue);
  };

  const onResetSearchFilter = () => {
    setStagingSearchFilter('');
  };

  const onChangeFilteredCampaigns = (id) => {
    setDataLoading(true);
    setCookie(campaignForPipelineCookieKey, id, expiry);
  };

  const onChangeContactType = (type) => {
    if (type !== selectedContactType) {
      setDataLoading(true);
      setCookie(contactTypeForPipelineCookieKey, type, expiry);
    }
  };

  const onResetFilters = () => {
    onChangeContactType(allKey);
    setStagingSearchFilter('');
  };

  const totalLoaded = pageSize * pageNumber;
  const filtersApplied = searchFilter || selectedContactType !== allKey;
  const moreDataToLoad = numResults > totalLoaded;

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          hideOverflow
        >
          <ColumnCenteredDiv>
            <PageTitleText>Pipeline</PageTitleText>
            {hasFilters && (
              <PipelineFiltersContainer>
                <StartAlignedFlexDiv largerGap>
                  <ClientSearchBox
                    value={stagingSearchFilter}
                    onChange={(e) => updateSearchFilter(e.target.value)}
                    onReset={onResetSearchFilter}
                  />
                  <StartAlignedMediumDarkTinyText
                    smallLineHeight
                    centered
                  >
                    {formatNumber(numResults)}
                    {searchFilter && ` matching`}{' '}
                    {selectedContactType === clientContactType
                      ? 'client'
                      : selectedContactType === leadContactType
                      ? 'lead'
                      : 'contact'}
                    {numResults !== 1 ? 's' : ''}&nbsp;
                    {moreDataToLoad ? (
                      <>
                        &nbsp;{textSeparatorChar}&nbsp; First{' '}
                        {formatNumber(totalLoaded)} results shown &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                    <SearchIconContainer>
                      {moreDataToLoad && (
                        <Tooltip
                          title={
                            <TooltipTitleText>Load next batch</TooltipTitleText>
                          }
                        >
                          <LoadMoreIcon
                            onClick={() => setPageNumber(pageNumber + 1)}
                          />
                        </Tooltip>
                      )}
                      {filtersApplied && (
                        <Tooltip
                          title={
                            <TooltipTitleText>Reset filters</TooltipTitleText>
                          }
                        >
                          <ResetFiltersIcon
                            onClick={() => {
                              if (filtersApplied) {
                                onResetFilters();
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    </SearchIconContainer>
                  </StartAlignedMediumDarkTinyText>
                </StartAlignedFlexDiv>
                <EndAlignedFlexDiv gap={5}>
                  <OptionsFilterDropdown
                    filtered={selectedCampaignIdArr}
                    options={campaignOptions}
                    label={
                      selectedCampaignIdArr?.length
                        ? campaignNameMap[selectedCampaignIdArr[0]]
                        : 'Select Campaign'
                    }
                    onChange={onChangeFilteredCampaigns}
                    fixedWidth={200}
                  />
                  <OptionsFilterDropdown
                    filtered={[selectedContactType]}
                    options={contactTypesForCampaigns}
                    label={
                      selectedContactType === allKey
                        ? 'All Contacts'
                        : selectedContactType === clientContactType
                        ? 'Clients'
                        : selectedContactType === leadContactType
                        ? 'Leads'
                        : ''
                    }
                    onChange={onChangeContactType}
                    fixedWidth={120}
                  />
                </EndAlignedFlexDiv>
              </PipelineFiltersContainer>
            )}
            {!campaignsLoading ? (
              <PipelineContainer>
                {laneKeys.map((baseKey, idx) => {
                  const key =
                    baseKey === sequenceCompletedCampaignMemberStatusKey
                      ? staleCampaignMemberStatusKey
                      : baseKey;
                  const obj = statusLabelMap[key];
                  const label = obj?.label || '';

                  const start = idx === 0;
                  const end = idx === laneKeys.length - 1;

                  const laneContacts = contactMap[key];
                  return (
                    <PipelineColumn
                      start={start}
                      end={end}
                    >
                      <PipelineColumnHeader
                        start={start}
                        end={end}
                      >
                        <PipelineColumnHeaderTitle status={key}>
                          {label}{' '}
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {obj?.description || ''}
                              </TooltipTitleText>
                            }
                          >
                            <InfoIcon
                              primary
                              small
                              largeLeftMargin
                            />
                          </Tooltip>
                        </PipelineColumnHeaderTitle>
                        <PipelineColumnHeaderSubtext>
                          {dataLoading ? (
                            <>&nbsp;</>
                          ) : (
                            `(${formatNumber(laneContacts.length)})`
                          )}
                        </PipelineColumnHeaderSubtext>
                      </PipelineColumnHeader>
                      <PipelineClientContainer end={end}>
                        {laneContacts.length ? (
                          <>
                            {laneContacts.map((contact) => {
                              const lastMessagedAt = contact?.lastMessagedAt;
                              return (
                                <PipelineClient status={key}>
                                  <PipelineClientName>
                                    {getFormattedFullNameFromUser(contact)}
                                  </PipelineClientName>
                                  <PipelineClientContacted>
                                    {lastMessagedAt === emptyIntValue ? (
                                      'Uncontacted'
                                    ) : (
                                      <>
                                        Last contacted{' '}
                                        {getTimestamp(
                                          new Date(
                                            lastMessagedAt,
                                          ).toISOString(),
                                          false,
                                          true,
                                        )}
                                      </>
                                    )}
                                  </PipelineClientContacted>
                                </PipelineClient>
                              );
                            })}
                          </>
                        ) : (
                          <ColumnCenteredDiv topMargin={20}>
                            <MediumNoDataIcon />
                            <LightDarkTinyText>
                              No contacts with status
                            </LightDarkTinyText>
                          </ColumnCenteredDiv>
                        )}
                      </PipelineClientContainer>
                    </PipelineColumn>
                  );
                })}
              </PipelineContainer>
            ) : (
              <LoadingIndicator fullScreen />
            )}
          </ColumnCenteredDiv>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Pipeline;

import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_AGENT_VERSION } from '../../../api/mutations/agent';
import {
  DarkMLText,
  MediumPrimaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
} from '../../../styles/shared-styled-components';
import { dropdownInputType } from '../../../utils/constants';
import Input from '../../Form/Input';

const CreateAgentVersionModal = ({ agents, isOpen, onClose, onSave }) => {
  const [createAgentVersionMutation, { loading: createAgentVersionLoading }] =
    useMutation(CREATE_AGENT_VERSION);

  const agentOptionsMap = agents?.map((a) => {
    return {
      key: a.id,
      label: `${a.name} (${a.locationName})`,
    };
  });

  const [version, setVersion] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [agentId, setAgentId] = useState();

  const onClickSave = () => {
    createAgentVersionMutation({
      variables: {
        version,
        name,
        description,
        agentId,
      },
      onCompleted: async () => {
        onSave(true);
        onClose();
      },
    });
  };

  const saveEnabled = !!version && !!name && !!description;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <DarkMLText>Create Agent Version</DarkMLText>
        <ModalInputsContainer>
          <Input
            id={'version'}
            value={version}
            label={'Version'}
            onChange={(e) => setVersion(e.target.value)}
          />
          <Input
            id={'name'}
            value={name}
            label={'Name'}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            id={'description'}
            value={description}
            label={'Description'}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            id={'agent'}
            value={agentId}
            label={'Agent'}
            onChange={(e) => setAgentId(e.target.value)}
            type={dropdownInputType}
            options={agentOptionsMap}
          />
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Create
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreateAgentVersionModal;

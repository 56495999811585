import gql from 'graphql-tag';

export const CREATE_LOCATION_USER = gql`
  mutation createLocationUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $role: String!
  ) {
    createLocationUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      role: $role
    ) {
      success
    }
  }
`;

export const EDIT_USER_PERSONAL_INFO = gql`
  mutation editUserPersonalInfo(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    editUserPersonalInfo(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      email
      firstName
      lastName
      phone
    }
  }
`;

export const EDIT_BUSINESS_USER_LOCATION_VIEW = gql`
  mutation editBusinessUserLocationView(
    $locationId: String
    $action: SelectionAction!
  ) {
    editBusinessUserLocationView(locationId: $locationId, action: $action)
  }
`;

export const ASSIGN_USERS_TO_LOCATION = gql`
  mutation assignUsersToLocation($userIds: [String]) {
    assignUsersToLocation(userIds: $userIds) {
      success
    }
  }
`;

import { useAuth0 } from '@auth0/auth0-react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async/lib';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import ApolloProviderWithToken from './ApolloProvider';
import Paths from './Paths';
import AuthRouter from './components/Auth/AuthRouter';
import ScrollToTop from './components/Micro/ScrollToTop';
import AgentAppConnection from './pages-app/AgentSetup/AgentAppConnection';
import AgentSelection from './pages-app/AgentSetup/AgentSelection/AgentSelection';
import InitializationPurgatory from './pages-app/AgentSetup/InitializationPurgatory';
import GHLSyncStatus from './pages-app/AppConnections/GHLSyncStatus';
import CallbackPage from './pages-app/Auth/Callback';
import Logout from './pages-app/Auth/Logout';
import MiddleRouter from './pages-app/Auth/MiddleRouter';
import CreateBusiness from './pages-app/Enterprise/CreateBusiness';
import Settings from './pages-app/Enterprise/Settings';
import BusinessOnboarding from './pages-app/Onboarding/Business';
import PersonalOnboarding from './pages-app/Onboarding/Personal';
import Appointment from './pages-app/Public/Appointment';
import AgentVersions from './pages-app/Tabs/AgentVersions';
import Agents from './pages-app/Tabs/Agents';
import HeadOffice from './pages-app/Tabs/Analytics/Dashboard';
import Revenue from './pages-app/Tabs/Analytics/Revenue/Revenue';
import Audience from './pages-app/Tabs/Campaigns/Audience';
import Campaigns from './pages-app/Tabs/Campaigns/Campaigns';
import EditPromo from './pages-app/Tabs/Campaigns/EditPromo';
import EditScript from './pages-app/Tabs/Campaigns/EditScript';
import ChatDemo from './pages-app/Tabs/ChatDemo';
import ChatEvents from './pages-app/Tabs/Chats/ChatEvents';
import Chats from './pages-app/Tabs/Chats/Chats';
import Pipeline from './pages-app/Tabs/Chats/Pipeline';
import Clients from './pages-app/Tabs/Clients';
import Sequences from './pages-app/Tabs/Sequences';
import Feedback from './pages-app/Tabs/Training/Feedback';
import Training from './pages-app/Tabs/Training/Training';
import Users from './pages-app/Tabs/Users';
import Terms from './pages-app/Terms';
import Homepage from './pages-website/Homepage/index';
import baseStyles from './styles';

const App = () => {
  const { user: auth0User } = useAuth0();

  useEffect(() => {
    if (auth0User) {
      posthog.identify(auth0User.email);
      posthog.capture('pageview', { path: window.location.pathname });
    } else {
      const userIdentity = posthog.get_distinct_id();
      if (!userIdentity) {
        const searchParams = new URLSearchParams(window.location.search);
        const uid = searchParams.get('uid');
        const postHogId = uid || uuidv4();
        posthog.identify(postHogId);
        posthog.capture('pageview', { path: window.location.pathname });
      }
    }
  }, [auth0User]);

  return (
    <ApolloProviderWithToken>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={baseStyles}>
            <ScrollToTop />
            <Routes>
              <Route
                path='*'
                element={
                  <Navigate
                    to={Paths.homepage}
                    replace
                  />
                }
              />
              {/* Website */}
              <Route
                path={Paths.homepage}
                element={<AuthRouter Component={Homepage} />}
              />
              <Route
                path={Paths.middleRouter}
                element={<AuthRouter Component={MiddleRouter} />}
              />
              {/* App */}
              {/* Onboarding */}
              <Route
                path={Paths.personalOnboarding}
                element={
                  <AuthRouter
                    Component={PersonalOnboarding}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.businessOnboarding}
                element={
                  <AuthRouter
                    Component={BusinessOnboarding}
                    authGated
                  />
                }
              />
              {/* Agent setup */}
              <Route
                path={Paths.agentSelection}
                element={
                  <AuthRouter
                    Component={AgentSelection}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.agentAppConnection}
                element={
                  <AuthRouter
                    Component={AgentAppConnection}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.ghlSyncStatus}
                element={
                  <AuthRouter
                    Component={GHLSyncStatus}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.initializationPurgatory}
                element={
                  <AuthRouter
                    Component={InitializationPurgatory}
                    authGated
                  />
                }
              />
              {/* Enterprise */}
              <Route
                path={Paths.createBusiness}
                element={
                  <AuthRouter
                    Component={CreateBusiness}
                    authGated
                  />
                }
              />
              {/* Analytics */}
              <Route
                path={Paths.dashboard}
                element={
                  <AuthRouter
                    Component={HeadOffice}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.revenue}
                element={
                  <AuthRouter
                    Component={Revenue}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.editPromo}
                element={
                  <AuthRouter
                    Component={EditPromo}
                    authGated
                  />
                }
              />
              {/* Chats */}
              <Route
                path={Paths.chats}
                element={
                  <AuthRouter
                    Component={Chats}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.events}
                element={
                  <AuthRouter
                    Component={ChatEvents}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.pipeline}
                element={
                  <AuthRouter
                    Component={Pipeline}
                    authGated
                  />
                }
              />
              {/* Training */}
              <Route
                path={Paths.training}
                element={
                  <AuthRouter
                    Component={Training}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.requests}
                element={
                  <AuthRouter
                    Component={Feedback}
                    authGated
                  />
                }
              />
              {/* Contacts */}
              <Route
                path={Paths.contacts}
                element={
                  <AuthRouter
                    Component={Clients}
                    authGated
                  />
                }
              />
              {/* Campaigns */}
              <Route
                path={Paths.campaigns}
                element={
                  <AuthRouter
                    Component={Campaigns}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.audience}
                element={
                  <AuthRouter
                    Component={Audience}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.editScript}
                element={
                  <AuthRouter
                    Component={EditScript}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.editPromo}
                element={
                  <AuthRouter
                    Component={EditPromo}
                    authGated
                  />
                }
              />
              {/* Agents */}
              <Route
                path={Paths.agents}
                element={
                  <AuthRouter
                    Component={Agents}
                    authGated
                  />
                }
              />
              {/* Demo */}
              <Route
                path={Paths.agentDemo}
                element={
                  <AuthRouter
                    Component={ChatDemo}
                    authGated
                  />
                }
              />
              {/* Sequences */}
              <Route
                path={Paths.sequences}
                element={
                  <AuthRouter
                    Component={Sequences}
                    authGated
                  />
                }
              />
              {/* Public */}
              <Route
                path={Paths.appointment}
                element={<AuthRouter Component={Appointment} />}
              />
              {/* Admin */}
              <Route
                path={Paths.users}
                element={
                  <AuthRouter
                    Component={Users}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.agentVersions}
                element={
                  <AuthRouter
                    Component={AgentVersions}
                    authGated
                  />
                }
              />
              {/* Legal */}
              <Route
                path={Paths.terms}
                element={
                  <AuthRouter
                    Component={Terms}
                    authGated
                  />
                }
              />
              {/* Admin */}
              <Route
                path={Paths.settings}
                element={
                  <AuthRouter
                    Component={Settings}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.callback}
                element={
                  <AuthRouter
                    Component={CallbackPage}
                    authGated
                  />
                }
              />
              <Route
                path={Paths.logout}
                element={
                  <AuthRouter
                    Component={Logout}
                    authGated
                  />
                }
              />
            </Routes>
          </ThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </ApolloProviderWithToken>
  );
};

export default App;

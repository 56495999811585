import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { emptyStringValue } from '../../utils/constants';
import {
  GET_APPOINTMENT_BY_ID,
  GET_CALENDAR_FOR_DATE,
  GET_CHAT_APPOINTMENTS,
} from '../queries/calendar';

export const useCalendarForDate = ({ date, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_CALENDAR_FOR_DATE, {
    fetchPolicy: 'cache-first',
    skip: skipCondition || !date,
    variables: {
      date,
    },
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getCalendarForDate', null),
  };
};

export const useAppointmentById = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(GET_APPOINTMENT_BY_ID, {
    fetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      id,
    },
  });

  return {
    error,
    loading,
    refetch,
    appointment: get(data, 'getAppointmentById', null),
  };
};

export const useChatAppointments = ({ chatId, onlyBooked, name }) => {
  const {
    data: res,
    error,
    loading,
    refetch,
  } = useQuery(GET_CHAT_APPOINTMENTS, {
    fetchPolicy: 'network-only',
    skip: !chatId || chatId?.toString() === emptyStringValue,
    variables: {
      chatId,
      onlyBooked,
      name,
    },
  });

  const data = get(res, 'getChatAppointments', null);

  return {
    error,
    loading,
    refetch,
    data,
  };
};

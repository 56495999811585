export const getUniqueElements = (arrayToFilterFrom, arrayToFilterAgainst) => {
  return arrayToFilterFrom.filter(
    (element) => !arrayToFilterAgainst.includes(element),
  );
};

export const getValueAtArrayIndex = (array, index) => {
  if (index >= 0 && index < array.length) {
    return array[index];
  } else {
    return null;
  }
};

export const getObjectFromArrayOfKeyLabelObjects = (arrayOfObjects) => {
  const obj = {};

  arrayOfObjects.map((val) => {
    obj[val.key] = val.label;
  });

  return obj;
};

export default {
  getUniqueElements,
  getValueAtArrayIndex,
  getObjectFromArrayOfKeyLabelObjects,
};

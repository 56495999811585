import { gql } from '@apollo/client';

export const EDIT_AGENT_SETTINGS = gql`
  mutation editAgentSettings($settings: JSON!) {
    editAgentSettings(settings: $settings) {
      success
    }
  }
`;

export const EDIT_LOCATION = gql`
  mutation editLocation(
    $numLocations: Int
    $addressInfo: JSON
    $addressInstructions: String
  ) {
    editLocation(
      numLocations: $numLocations
      addressInfo: $addressInfo
      addressInstructions: $addressInstructions
    ) {
      numLocations
      address
      addressInstructions
    }
  }
`;

export const EDIT_LOCATION_CONTACT_INFO = gql`
  mutation editLocationContactInfo(
    $businessPhoneNumberForClient: String
    $contactPhoneNumbers: JSON
    $notificationPreference: JSON
  ) {
    editLocationContactInfo(
      businessPhoneNumberForClient: $businessPhoneNumberForClient
      contactPhoneNumbers: $contactPhoneNumbers
      notificationPreference: $notificationPreference
    ) {
      success
    }
  }
`;

export const EDIT_HOURS = gql`
  mutation editHours($hours: JSON) {
    editHours(hours: $hours)
  }
`;

export const EDIT_LINKS = gql`
  mutation editLinks($links: JSON) {
    editLinks(links: $links)
  }
`;

export const EDIT_STAFF_MEMBERS = gql`
  mutation editStaffMembers($staff: JSON!) {
    editStaffMembers(staff: $staff) {
      success
    }
  }
`;

export const EDIT_CATEGORY_INCLUSIONS = gql`
  mutation editCategoryInclusions($categories: JSON) {
    editCategoryInclusions(categories: $categories)
  }
`;

export const EDIT_CATEGORY_ASSIGNMENT = gql`
  mutation editCategoryAssignment(
    $categoryId: String!
    $assignedCategory: String!
  ) {
    editCategoryAssignment(
      categoryId: $categoryId
      assignedCategory: $assignedCategory
    ) {
      success
    }
  }
`;

export const EDIT_OFFERINGS = gql`
  mutation editOfferings($offerings: JSON!) {
    editOfferings(offerings: $offerings) {
      success
    }
  }
`;

export const EDIT_PROMPT = gql`
  mutation editPrompt($prompt: JSON) {
    editPrompt(prompt: $prompt)
  }
`;

export const CREATE_BOOKING_FLOW = gql`
  mutation createBookingFlow(
    $categoryId: String!
    $title: String!
    $utterance: String!
    $universal: Boolean!
  ) {
    createBookingFlow(
      categoryId: $categoryId
      title: $title
      utterance: $utterance
      universal: $universal
    )
  }
`;

export const CREATE_FAQ = gql`
  mutation createFaq(
    $categoryId: String!
    $title: String!
    $utterance: String!
    $answer: String!
    $universal: Boolean!
  ) {
    createFaq(
      categoryId: $categoryId
      title: $title
      utterance: $utterance
      answer: $answer
      universal: $universal
    )
  }
`;

export const EDIT_FAQ = gql`
  mutation editFaq($id: String!, $utterance: String!, $resolution: JSON!) {
    editFaq(id: $id, utterance: $utterance, resolution: $resolution)
  }
`;

export const DELETE_PROMPT = gql`
  mutation deletePrompt($id: String!) {
    deletePrompt(id: $id) {
      success
    }
  }
`;

export const EDIT_MEMBERSHIPS = gql`
  mutation editMemberships($memberships: JSON!) {
    editMemberships(memberships: $memberships)
  }
`;

export const EDIT_POLICY = gql`
  mutation editPolicy($policy: JSON!) {
    editPolicy(policy: $policy)
  }
`;

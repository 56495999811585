import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import React from 'react';
import {
  colorScheme,
  mediumDarkText,
  statusColorMap,
  VisxText,
} from '../shared-analytics-styles';

const PieChart = ({ size, data, title, alt, secondary, ternary }) => {
  // Calculate the center of the pie
  let width;
  let height;
  let innerRadius;
  let outerRadius;

  if (size === 'medium') {
    width = 350;
    height = 350;
    innerRadius = 20;
    outerRadius = 100;
  }
  const padAngle = 0.05;

  // Calculate the center of the pie
  const centerX = width / 2;
  const centerY = height / 2;

  return (
    <svg
      width={width}
      height={height}
    >
      <VisxText
        x={width / 2}
        y={60}
        fontSize={14}
        textAnchor='middle'
        fill={mediumDarkText}
      >
        {title}
      </VisxText>
      <Group
        top={centerY + 10}
        left={centerX}
      >
        <Pie
          data={data}
          pieValue={(d) => d.value}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          padAngle={padAngle}
        >
          {(pie) =>
            pie.arcs.map((arc, index) => {
              const data = arc.data;
              const status = data?.status;
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const color = status
                ? statusColorMap[status]
                : alt
                ? colorScheme[index + 4]
                : secondary
                ? colorScheme[index + 3]
                : ternary
                ? colorScheme[index + 2]
                : colorScheme[index];

              return (
                <g key={`arc-${index}`}>
                  <path
                    d={pie.path(arc)}
                    fill={color}
                  />
                  <VisxText
                    x={centroidX}
                    y={centroidY - 18}
                    dy='.10em'
                    dx='-.50em'
                    fontSize={11}
                    fontWeight='bold'
                    textAnchor='middle'
                    fill='#fff'
                  >
                    {`${data?.label}`}
                  </VisxText>
                  <VisxText
                    x={centroidX}
                    y={centroidY - 4}
                    dy='.10em'
                    dx='-.50em'
                    fontSize={11}
                    fontWeight='bold'
                    textAnchor='middle'
                    fill='#fff'
                  >
                    {`(${arc?.value}%)`}
                  </VisxText>
                </g>
              );
            })
          }
        </Pie>
      </Group>
    </svg>
  );
};

export default PieChart;

import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { EDIT_HOURS } from '../../../../api/mutations/training';
import {
  ContainerWithInputWidth,
  MediumDarkSmallText,
  NextPageButton,
  PreviousPageButton,
  StartAlignedDarkSmallText,
  StartAlignedMediumDarkEssText,
  UneditableInputContainer,
} from '../../../../styles/shared-styled-components';
import {
  dropdownInputType,
  hours as hoursOptions,
  orderedDaysOfWeekMap,
} from '../../../../utils/constants';
import { getWordWithCapitalizedFirstLetter } from '../../../../utils/name';
import SplitInput from '../../../Form/SplitInput/SplitInput';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  PromptContainer,
  PromptsSection,
} from '../../shared-training-components';
import {
  ActiveDayToggleContainer,
  DayHoursTextContainer,
  SliderContainer,
} from './styled';

const sortHoursByDay = (hours) => {
  if (!hours?.length) {
    return null;
  }

  const formattedHours = hours.map((h) => {
    return {
      ...h,
      index: orderedDaysOfWeekMap[h.dayOfWeek],
    };
  });

  return sortBy(formattedHours, (h) => h.index);
};

const Hours = ({ res }) => {
  const { data, loading, refetch } = res;

  const [saveHoursMutation] = useMutation(EDIT_HOURS);

  const hoursData = data?.hours;
  const parsedHours = hoursData ? sortHoursByDay(hoursData) : null;

  const [slide, setSlide] = useState(0);
  const [hours, setHours] = useState(parsedHours);
  const [lastSavedHours, setLastSavedHours] = useState(parsedHours);

  useEffect(() => {
    if (data) {
      setHours(parsedHours);
      setLastSavedHours(parsedHours);
    }
  }, [data]);

  let dayDisplayed;
  let dayDisplayedLabel;
  let dayDisplayedIsOpen;
  let dayDisplayedId;
  if (hours) {
    dayDisplayed = hours[slide];
    dayDisplayedLabel = getWordWithCapitalizedFirstLetter(
      dayDisplayed.dayOfWeek,
    );
    dayDisplayedIsOpen = dayDisplayed.startTime && dayDisplayed.endTime;
    dayDisplayedId = dayDisplayed.id;
  }

  const onChangeHours = (id, fieldName, value) => {
    const currentHours = [...hours];

    const editedHours = currentHours.map((day) => {
      const dayId = day.id;
      if (dayId === id) {
        return {
          ...day,
          [fieldName]: value,
        };
      } else {
        return day;
      }
    });
    setHours(sortHoursByDay(editedHours));
  };

  const autoSave = async () => {
    if (JSON.stringify(hours) !== JSON.stringify(lastSavedHours)) {
      console.log('Saving hours...', hours);

      try {
        await saveHoursMutation({
          variables: { hours },
          onCompleted: async (data) => {
            const updatedSavedHours = data.editHours;
            console.log('updatedSavedHours', updatedSavedHours);
            setLastSavedHours(updatedSavedHours);
            refetch();
          },
        });
        console.log('Hours saved successfully');
      } catch (error) {
        console.error('Error saving Hours:', error);
      }
    }
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <ContainerWithInputWidth verticalMargin={20}>
          <DayHoursTextContainer>
            <StartAlignedDarkSmallText>
              {dayDisplayedLabel} Hours
            </StartAlignedDarkSmallText>
            <ActiveDayToggleContainer>
              <MediumDarkSmallText>
                {dayDisplayedIsOpen ? 'Open' : 'Closed'} on {dayDisplayedLabel}
              </MediumDarkSmallText>
              <SwitchToggle
                value={dayDisplayedIsOpen}
                onChange={() => {
                  const currentHours = [...hours];
                  const editedHours = currentHours.map((day) => {
                    const dayId = day.id;
                    if (dayId === dayDisplayedId) {
                      if (dayDisplayedIsOpen) {
                        return {
                          ...day,
                          startTime: null,
                          endTime: null,
                        };
                      } else {
                        return {
                          ...day,
                          startTime: '09:00:00',
                          endTime: '17:00:00',
                        };
                      }
                    } else {
                      return day;
                    }
                  });
                  setHours(sortHoursByDay(editedHours));
                }}
                onBlur={() => autoSave()}
              />
            </ActiveDayToggleContainer>
          </DayHoursTextContainer>
          {dayDisplayedIsOpen ? (
            <SplitInput
              id={'hours'}
              splitInputs={[
                {
                  value: dayDisplayed.startTime,
                  onChange: (e) =>
                    onChangeHours(dayDisplayedId, 'startTime', e.target.value),
                  label: 'Start time',
                  type: dropdownInputType,
                  options: hoursOptions,
                  onBlur: autoSave,
                },
                {
                  value: dayDisplayed.endTime,
                  onChange: (e) =>
                    onChangeHours(dayDisplayedId, 'endTime', e.target.value),
                  label: 'End time',
                  type: dropdownInputType,
                  options: hoursOptions,
                  onBlur: autoSave,
                },
              ]}
              useSmallGap={true}
            />
          ) : (
            <UneditableInputContainer>
              <StartAlignedMediumDarkEssText>
                No available hours on {dayDisplayedLabel}
              </StartAlignedMediumDarkEssText>
            </UneditableInputContainer>
          )}
          <SliderContainer>
            <PreviousPageButton
              onClick={() => {
                if (slide === 0) {
                  setSlide(hours?.length - 1);
                } else {
                  setSlide(slide - 1);
                }
              }}
            />
            <NextPageButton
              onClick={() => {
                if (slide === hours?.length - 1) {
                  setSlide(0);
                } else {
                  setSlide(slide + 1);
                }
              }}
            />
          </SliderContainer>
        </ContainerWithInputWidth>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Hours;

import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import { default as React, useEffect, useState } from 'react';
import { EDIT_MEMBERSHIPS } from '../../../../api/mutations/training';
import {
  StartAlignedLightDarkLargeTinyText,
  StartAlignedMediumDarkEssText,
} from '../../../../styles/shared-styled-components';
import { buttonTriggeredAutoSaveDelayMs } from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTextContainer,
  ListInputTitleContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const sortMemberships = (memberships) => {
  return sortBy(memberships, [(m) => m.name]);
};

const getStringifiedMemberships = (memberships) => {
  return JSON.stringify(
    memberships.map((m) => {
      return {
        id: m.id,
        name: m.name,
        isActive: m.isActive,
      };
    }),
  );
};

const Memberships = ({ res }) => {
  const { data, loading, refetch } = res;

  const [editMembershipsMutation, { loading: saving }] =
    useMutation(EDIT_MEMBERSHIPS);

  const membershipData = sortMemberships(data || []);
  const stringifiedMemberships = JSON.stringify(membershipData);

  const [memberships, setMemberships] = useState(membershipData);
  const [lastSavedMemberships, setLastSavedMemberships] =
    useState(membershipData);

  useEffect(() => {
    if (data) {
      setMemberships(membershipData);
      setLastSavedMemberships(membershipData);
    }
  }, [stringifiedMemberships]);

  const onToggleMembershipInclusion = (id, isActive) => {
    const updatedMemberships = memberships.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          isActive,
        };
      }
      return m;
    });
    setMemberships(sortMemberships(updatedMemberships));
  };

  const autoSave = async () => {
    if (
      getStringifiedMemberships(memberships) !==
      getStringifiedMemberships(lastSavedMemberships)
    ) {
      console.log('Saving memberships...', memberships);

      try {
        await editMembershipsMutation({
          variables: { memberships },
          onCompleted: async (data) => {
            const updatedMemberships = data.editMemberships;
            setLastSavedMemberships(updatedMemberships);
            refetch();
          },
        });
        console.log('Memberships saved successfully');
      } catch (error) {
        console.error('Error saving memberships:', error);
      }
    }
  };

  // Auto-save interval effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!saving) {
        autoSave();
      }
    }, buttonTriggeredAutoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [memberships, lastSavedMemberships]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const hasValidMemberships = !!memberships?.length;

  return (
    <PromptsSection hideOverflow>
      <PromptContainer overflow>
        <ListInputTitleContainer>
          <PromptTitleText>
            {hasValidMemberships
              ? `Select which membership plans are active`
              : `No membership plans`}
          </PromptTitleText>
        </ListInputTitleContainer>
        {hasValidMemberships ? (
          <ListInputSection>
            {memberships.map((membership) => {
              const {
                id,
                name,
                isActive,
                retailDiscountPercentage,
                serviceDiscountPercentage,
              } = membership;
              const discountText =
                retailDiscountPercentage && serviceDiscountPercentage
                  ? `${serviceDiscountPercentage}% off services, ${retailDiscountPercentage}% off retail`
                  : retailDiscountPercentage
                  ? `${retailDiscountPercentage}% off retail`
                  : serviceDiscountPercentage
                  ? `${serviceDiscountPercentage}% off services`
                  : `No discount found`;
              return (
                <ListInputContainer>
                  <ListInputTextContainer>
                    <StartAlignedMediumDarkEssText>
                      {name}
                    </StartAlignedMediumDarkEssText>
                    <StartAlignedLightDarkLargeTinyText>
                      {discountText}
                    </StartAlignedLightDarkLargeTinyText>
                  </ListInputTextContainer>
                  <SwitchToggle
                    value={isActive}
                    onChange={() => onToggleMembershipInclusion(id, !isActive)}
                  />
                </ListInputContainer>
              );
            })}
          </ListInputSection>
        ) : (
          <></>
        )}
      </PromptContainer>
    </PromptsSection>
  );
};

export default Memberships;

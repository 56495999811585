import { gql } from '@apollo/client';

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($appointment: JSON!) {
    success
  }
`;

export const SAVE_CHAT_APPOINTMENT_ASSIGNMENTS = gql`
  mutation saveChatAppointmentAssignments(
    $appointments: JSON!
    $campaignId: String!
    $appliedPromo: JSON
  ) {
    saveChatAppointmentAssignments(
      appointments: $appointments
      campaignId: $campaignId
      appliedPromo: $appliedPromo
    ) {
      success
    }
  }
`;

export const EDIT_CHAT_APPOINTMENT = gql`
  mutation editChatAppointment($appointment: JSON!) {
    editChatAppointment(appointment: $appointment) {
      success
    }
  }
`;

export const UNASSIGN_CHAT_APPOINTMENT = gql`
  mutation unassignChatAppointment($appointmentId: String!) {
    unassignChatAppointment(appointmentId: $appointmentId) {
      success
    }
  }
`;

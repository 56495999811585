import { useMutation } from '@apollo/client';
import { default as React, useEffect, useState } from 'react';
import { EDIT_LINKS } from '../../../../api/mutations/training';
import { linkTypeMap } from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const linkTypes = Object.keys(linkTypeMap);

const Links = ({ res }) => {
  const { data, loading, refetch } = res;

  const [saveLinksMutation] = useMutation(EDIT_LINKS);

  const linkData = data?.links || {};

  const [links, setLinks] = useState(linkData);
  const [lastSavedLinks, setLastSavedLinks] = useState(linkData);

  useEffect(() => {
    if (data) {
      setLinks(linkData);
      setLastSavedLinks(linkData);
    }
  }, [data]);

  const onUpdateLink = (key, value) => {
    const updatedLinks = {
      ...links,
      [key]: value,
    };
    setLinks(updatedLinks);
  };

  const autoSave = async () => {
    if (JSON.stringify(links) !== JSON.stringify(lastSavedLinks)) {
      console.log('Saving links...', links);

      try {
        await saveLinksMutation({
          variables: { links },
          onCompleted: async (data) => {
            const updatedSavedLinks = data.editLinks;
            console.log('updatedSavedLinks', updatedSavedLinks);
            setLastSavedLinks(updatedSavedLinks);
            refetch();
          },
        });
        console.log('Links saved successfully');
      } catch (error) {
        console.error('Error saving links:', error);
      }
    }
  };

  // Auto-save interval effect (save every 5 seconds)
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSave();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [links, lastSavedLinks]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      {linkTypes.map((typeKey) => {
        const label = linkTypeMap[typeKey];
        return (
          <PromptContainer>
            <PromptTitleText>{label}</PromptTitleText>
            <PromptInput
              value={links[typeKey]}
              onChange={(e) => onUpdateLink(typeKey, e.target.value)}
              onBlur={() => autoSave()}
            />
          </PromptContainer>
        );
      })}
    </PromptsSection>
  );
};

export default Links;

import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  CenteredDiv,
  ColumnCenteredDiv,
  MediumDarkSMText,
} from '../../../styles/shared-styled-components';

export const EnterprisesContainer = styled(ColumnCenteredDiv)`
  width: 80%;
  ${AutoHorizontalMargins}
`;

export const EnterpriseContainer = styled(ColumnCenteredDiv)`
  width: 100%;
`;

export const BusinessNameText = styled(MediumDarkSMText)`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.muiInput
      : props.special
      ? props.theme.color.darkGold
      : 'inherit'};
  &:hover {
    color: ${(props) =>
      props.special
        ? props.theme.color.gold
        : !props.disabled
        ? props.theme.color.brightGreen
        : null};
  }
`;

export const CreateButtonContainer = styled(CenteredDiv)`
  margin-top: 20px;
`;

import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';
import React, { useState } from 'react';
import {
  colorScheme,
  mediumDarkText,
  primary,
  VisxText,
} from '../shared-analytics-styles';

// Sample Data
const data = [
  { label: 'Sep 23-29', value: 10 },
  { label: 'Sep 30-Oct 6', value: 27 },
  { label: 'Oct 7-13', value: 15 },
  { label: 'Oct 14-20', value: 25 },
  { label: 'Oct 21-Now', value: 6 },
];

const BarChart = ({
  yAxisLabel,
  width = 500,
  height = 270,
  margin = { top: 20, right: 30, bottom: 50, left: 50 },
}) => {
  const [tooltipData, setTooltipData] = useState(null);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData: hoveredData,
  } = useTooltip();

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Define scales
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map((d) => d.label),
    padding: 0.3,
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, Math.max(...data.map((d) => d.value))],
  });

  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={width}
        height={height}
      >
        <VisxText
          x={width / 2}
          y={11}
          fontSize={14}
          textAnchor='middle'
          fill={mediumDarkText}
        >
          {yAxisLabel}
        </VisxText>
        <Group
          top={40}
          left={margin.left}
        >
          {data.map((d, i) => {
            const barHeight = yMax - yScale(d.value);
            const barX = xScale(d.label);
            const barY = yMax - barHeight;
            const barWidth = xScale.bandwidth();

            return (
              <React.Fragment key={`bar-${i}`}>
                <Bar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={colorScheme[i + 1]}
                  onMouseMove={(event) => {
                    const rect = event.currentTarget.getBoundingClientRect();
                    showTooltip({
                      tooltipData: d,
                      tooltipLeft: rect.x + rect.width / 2,
                      tooltipTop: rect.y,
                    });
                  }}
                  onMouseLeave={hideTooltip}
                />
                {/* Display value on top of the bar */}
                <text
                  x={barX + barWidth / 2}
                  y={barY + 17} // Slightly above the bar
                  fontSize={12}
                  fontFamily='SeldonFont'
                  textAnchor='middle'
                  fill={'white'}
                >
                  {d.value}
                </text>
              </React.Fragment>
            );
          })}
          <AxisBottom
            top={yMax}
            scale={xScale}
            stroke={primary}
            tickStroke={primary}
            hideTicks
            tickLabelProps={{
              fontSize: 11,
              textAnchor: 'middle',
              fontFamily: 'SeldonFont',
              dy: '0.5em',
            }}
          />
        </Group>
      </svg>
    </div>
  );
};

export default BarChart;

import { sortBy } from 'lodash';
import { default as React, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyChatEvents } from '../../../../api/hooks/chat';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { FilterSectionContainer } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../../../components/NavBar/Header';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import Paths from '../../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  CenteredDivWithLargerGap,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  SecondaryBackIcon,
  StartAlignedLargeTinyText,
  TinyPrimaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  completeStatusKey,
  stagingStatusKey,
  textSeparatorChar,
} from '../../../../utils/constants';
import { getWordWithCapitalizedFirstLetter } from '../../../../utils/name';
import { getUserBusiness } from '../../../../utils/user';
import { StatusChip } from '../../Campaigns/Campaigns/styled';

const eventLabelMap = {
  follow_up: <>📲&nbsp;&nbsp;Follow-up requests</>,
  appointment: <>🗓️&nbsp;&nbsp;Appointment bookings</>,
  bookmark: <>🔖 &nbsp;&nbsp;Bookmarks</>,
};

const getTransactionIcon = (key, count) => {
  return (
    <>
      {eventLabelMap[key]} ({count})
    </>
  );
};

const revenueTypes = [
  {
    key: 'a',
    label: 'Services',
  },
  {
    key: 'b',
    label: 'Add-ons',
  },
  {
    key: 'a',
    label: 'Product',
  },
];

const appointmentData = sortBy(
  [
    {
      name: 'Jodie Smith',
      date: 'October 29th',
      datetime: '2024-10-29T14:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 165,
      transactions: [
        {
          type: 'appt',
          price: 140,
          description: 'Swedish Massage with Kelly',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Warm Stone Add-On',
        },
      ],
    },
    {
      name: 'Mark Johnson',
      date: 'November 5th',
      datetime: '2024-11-05T11:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 110,
      transactions: [
        {
          type: 'appt',
          price: 110,
          description: '60-Minute Deep Tissue Massage with Sarah',
        },
      ],
    },
    {
      name: 'Emily Turner',
      date: 'October 25th',
      datetime: '2024-10-25T16:30:00Z',
      status: 'complete',
      type: 'appt',
      total: 195,
      transactions: [
        {
          type: 'appt',
          price: 150,
          description: 'Signature Facial with Amanda',
        },
        {
          type: 'product',
          price: 45,
          description: 'Cleansing Serum',
        },
      ],
    },
    {
      name: 'Michael Clark',
      date: 'November 2nd',
      datetime: '2024-11-02T09:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 135,
      transactions: [
        {
          type: 'appt',
          price: 120,
          description: '90-Minute Assisted Stretch with John',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Aromatherapy Add-On',
        },
      ],
    },
    {
      name: 'Samantha Brooks',
      date: 'October 20th',
      datetime: '2024-10-20T13:45:00Z',
      status: 'complete',
      type: 'appt',
      total: 220,
      transactions: [
        {
          type: 'appt',
          price: 180,
          description: 'Hot Stone Massage with Megan',
        },
        {
          type: 'addon',
          price: 20,
          description: 'CBD Oil Add-On',
        },
        {
          type: 'product',
          price: 20,
          description: 'Relaxation Oil',
        },
      ],
    },
    {
      name: 'Chris Evans',
      date: 'November 10th',
      datetime: '2024-11-10T17:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 155,
      transactions: [
        {
          type: 'appt',
          price: 140,
          description: 'Sports Massage with David',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Stretch Add-On',
        },
      ],
    },
    {
      name: 'Linda Foster',
      date: 'October 18th',
      datetime: '2024-10-18T18:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 275,
      transactions: [
        {
          type: 'appt',
          price: 220,
          description: '90-Minute Couples Massage',
        },
        {
          type: 'addon',
          price: 30,
          description: 'Hot Towel Treatment',
        },
        {
          type: 'product',
          price: 25,
          description: 'Massage Lotion',
        },
      ],
    },
    {
      name: 'Tom Richards',
      date: 'November 12th',
      datetime: '2024-11-12T15:30:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 95,
      transactions: [
        {
          type: 'appt',
          price: 95,
          description: 'Express Facial with Laura',
        },
      ],
    },
    {
      name: 'Sarah Williams',
      date: 'October 30th',
      datetime: '2024-10-30T12:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 160,
      transactions: [
        {
          type: 'appt',
          price: 130,
          description: 'Stretch Therapy with Jeff',
        },
        {
          type: 'addon',
          price: 30,
          description: 'Compression Therapy Add-On',
        },
      ],
    },
    {
      name: 'Robert Peterson',
      date: 'November 8th',
      datetime: '2024-11-08T10:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 130,
      transactions: [
        {
          type: 'appt',
          price: 130,
          description: 'Therapeutic Massage with Alice',
        },
      ],
    },
  ],
  (a) => a.datetime,
).reverse();

const Revenue = () => {
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const business = getUserBusiness(user);
  const businessName = business?.name;

  const [cursor, setCursor] = useState();
  const [stagingContactNameSearch, setStagingContactNameSearch] = useState('');
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const {
    events: interactionsMap,
    loading,
    refetch,
  } = useMyChatEvents({ cursor, contactName: contactNameSearch });

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <CenteredDivWithLargerGap bottomMargin={10}>
            <Tooltip
              title={<TooltipTitleText>Back to dashboard</TooltipTitleText>}
              placement='left'
            >
              <SecondaryBackIcon onClick={() => navigate(Paths.dashboard)} />
            </Tooltip>
            <PageTitleText>Revenue Breakdown</PageTitleText>
          </CenteredDivWithLargerGap>
          <CardPageSection>
            <FilterSectionContainer spaced>
              <ClientSearchBox entityLabel='client' />
              <div></div>
              <OptionsFilterDropdown
                label='Revenue Source'
                options={revenueTypes}
                filtered={revenueTypes.map((t) => t.key)}
              />
            </FilterSectionContainer>
            <MediumGapColumnCenteredDiv>
              {appointmentData.map((a) => {
                const { total, date, transactions, name, status } = a;
                return (
                  <BaseCard>
                    <ColumnDivWithGap>
                      <CardHeaderContainer>
                        <DarkEssText>
                          ${total} from {name}
                          <LightDarkTextSpan>
                            {' '}
                            {textSeparatorChar} {date}
                          </LightDarkTextSpan>
                        </DarkEssText>
                        <StatusChip
                          status={
                            status === 'complete'
                              ? completeStatusKey
                              : stagingStatusKey
                          }
                          fixedMinWidth={80}
                        >
                          {getWordWithCapitalizedFirstLetter(status)}
                        </StatusChip>
                      </CardHeaderContainer>
                      <StartAlignedLargeTinyText>
                        {transactions.length ? (
                          <StartAlignedLargeTinyText>
                            {transactions.map((t, idx) => (
                              <>
                                {t.type === 'appt'
                                  ? '🗓️'
                                  : t.type === 'addon'
                                  ? '➕'
                                  : t.type === 'product'
                                  ? '🧴'
                                  : ''}
                                &nbsp;&nbsp; ${t.price} - &nbsp;
                                {t.description}
                                <br></br>
                              </>
                            ))}
                          </StartAlignedLargeTinyText>
                        ) : (
                          <></>
                        )}
                      </StartAlignedLargeTinyText>
                    </ColumnDivWithGap>
                    <CardActionsContainer>
                      <TinyPrimaryButton
                        largePadding
                        onClick={() => {}}
                      >
                        View client LTV
                      </TinyPrimaryButton>
                    </CardActionsContainer>
                  </BaseCard>
                );
              })}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Revenue;

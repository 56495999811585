import { useMutation } from '@apollo/client';
import React, { useContext, useRef, useState } from 'react';
import { useAgentVersions, useAgents } from '../../../api/hooks/agent';
import {
  DEPLOY_AGENT_SPECIFIC_VERSION,
  DEPLOY_GLOBAL_AGENT_VERSION,
} from '../../../api/mutations/agent';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import CreateAgentVersionModal from '../../../components/Modals/CreateAgentVersionModal/CreateAgentVersionModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BaseCard,
  CardPageSection,
  CardPageSectionActionsContainer,
  ColumnCenteredDiv,
  ColumnStartAlignedDiv,
  CompleteButtonCheckmarkIcon,
  ContentContainer,
  ExtraSmallSecondaryButton,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  SmallLightDarkTextSpan,
  SmallPrimaryButton,
  SmallSuccessfulButton,
  StartAlignedLightDarkExtraSmallText,
  StartAlignedMediumDarkSmallText,
} from '../../../styles/shared-styled-components';
import { textSeparatorChar } from '../../../utils/constants';

const AgentVersions = () => {
  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const contentContainerRef = useRef();

  const {
    agents,
    loading: agentsLoading,
    refetch: refetchAgents,
  } = useAgents();
  const {
    agentVersions,
    loading: agentVersionsLoading,
    refetch: refetchAgentVersions,
  } = useAgentVersions({ global: true });
  const [
    deployAgentVersionGloballyMutation,
    { loading: deployAgentVersionGloballyLoading },
  ] = useMutation(DEPLOY_GLOBAL_AGENT_VERSION);
  const [
    deployAgentSpecificVersionMutation,
    { loading: deployAgentSpecificVersionLoading },
  ] = useMutation(DEPLOY_AGENT_SPECIFIC_VERSION);

  const agentNameMap = {};
  agents?.map((a) => {
    agentNameMap[a.id] = `${a.name} (${a.locationName})`;
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onDeployGlobally = (globalAgentVersionId) => {
    deployAgentVersionGloballyMutation({
      variables: {
        globalAgentVersionId,
      },
      onCompleted: async () => {
        await refetchAgentVersions();
        await refetchAgents();
        setSnackbarMessage('Deployed globally');
      },
    });
  };

  const onDeployForAgent = (agentId, agentVersionId) => {
    deployAgentSpecificVersionMutation({
      variables: {
        agentId,
        agentVersionId,
      },
      onCompleted: async () => {
        await refetchAgentVersions();
        await refetchAgents();
        setSnackbarMessage('Deployed for agent');
      },
    });
  };

  if (agentVersionsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    agentVersions && (
      <>
        <MetaSetter
          title={`Agent Versions`}
          description={`Agent Versions`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            ref={contentContainerRef}
          >
            <ColumnCenteredDiv>
              <PageTitleText>Agent Versions</PageTitleText>
            </ColumnCenteredDiv>
            <CardPageSection>
              <CardPageSectionActionsContainer>
                <ExtraSmallSecondaryButton
                  onClick={() => setCreateModalOpen(true)}
                >
                  <SmallAddIcon /> Create version
                </ExtraSmallSecondaryButton>
              </CardPageSectionActionsContainer>
              {agentVersions?.length ? (
                <MediumGapColumnCenteredDiv>
                  {agentVersions.map((agentVersion) => {
                    const {
                      id,
                      version,
                      name,
                      description,
                      deployedGlobally,
                      agentId,
                      isCurrent,
                    } = agentVersion;

                    return (
                      <BaseCard key={id}>
                        <ColumnStartAlignedDiv>
                          <StartAlignedMediumDarkSmallText>
                            v{version}: {name} {textSeparatorChar}{' '}
                            <SmallLightDarkTextSpan>
                              {agentId ? `${agentNameMap[agentId]}` : 'Global'}
                            </SmallLightDarkTextSpan>
                          </StartAlignedMediumDarkSmallText>
                          <StartAlignedLightDarkExtraSmallText>
                            {description}
                          </StartAlignedLightDarkExtraSmallText>
                        </ColumnStartAlignedDiv>
                        <ColumnStartAlignedDiv topMargin={8}>
                          {!agentId ? (
                            <>
                              {deployedGlobally ? (
                                <SmallSuccessfulButton
                                  removeMargins
                                  onClick={() => onDeployGlobally(id)}
                                >
                                  Deployed globally{' '}
                                  <CompleteButtonCheckmarkIcon />
                                </SmallSuccessfulButton>
                              ) : (
                                <SmallPrimaryButton
                                  removeMargins
                                  onClick={() => onDeployGlobally(id)}
                                >
                                  Deploy globally
                                </SmallPrimaryButton>
                              )}
                            </>
                          ) : (
                            <>
                              {isCurrent ? (
                                <SmallSuccessfulButton removeMargins>
                                  Deployed for agent{' '}
                                  <CompleteButtonCheckmarkIcon />
                                </SmallSuccessfulButton>
                              ) : (
                                <SmallPrimaryButton
                                  removeMargins
                                  onClick={() => onDeployForAgent(agentId, id)}
                                >
                                  Deploy for agent
                                </SmallPrimaryButton>
                              )}
                            </>
                          )}
                        </ColumnStartAlignedDiv>
                      </BaseCard>
                    );
                  })}
                </MediumGapColumnCenteredDiv>
              ) : (
                <MediumGapColumnCenteredDiv>
                  No versions
                </MediumGapColumnCenteredDiv>
              )}
            </CardPageSection>
          </ContentContainer>
        </PageContainer>
        <CreateAgentVersionModal
          agents={agents}
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSave={() => {
            setSnackbarMessage('Success');
            refetchAgentVersions();
          }}
        />
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
      </>
    )
  );
};

export default AgentVersions;

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  ScrollWrapper,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  multiLocationBusinessOwnerRole,
  multiLocationPrivilegedRoles,
  singleLocationBusinessType,
  superAdminRole,
} from '../../../utils/constants';
import { getResolvedPage } from '../../../utils/routing';
import { getUserBusiness, getUserLocationName } from '../../../utils/user';
import { screenWidthIsMobileSizeOrSmaller } from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  BottomSection,
  CampaignTabIcon,
  ChatTabIcon,
  ClientsTabIcon,
  CollapseDrawerArrowIcon,
  DemoTabIcon,
  DrawerContainer,
  ExpandDrawerArrowIcon,
  HeadOfficeTabIcon,
  LocationViewText,
  SequenceTabIcon,
  TabContainer,
  TabSection,
  TabSubtext,
  TabText,
  TabTextContainer,
  TabsContainer,
  TrainingTabIcon,
  UsersTabIcon,
} from './styled';

const Drawer = ({ open, expanded, onExpand, onCollapse, pathName }) => {
  const navigate = useNavigate();
  const {
    inDemoMode,
    inMonitoringMode,
    width,
    isOnboardingPage,
    isAgentSetupPage,
    role,
    user,
  } = useContext(BaseContext);

  const isMobileScreen = screenWidthIsMobileSizeOrSmaller(width);
  const isSuperAdmin = role === superAdminRole;

  const handleDrawerClick = () => {
    if (expanded) {
      onCollapse();
    } else {
      onExpand();
    }
  };

  const business = getUserBusiness(user);
  const type = business?.type;
  const isMultiLocationBusinessOwner =
    role === multiLocationBusinessOwnerRole ||
    (multiLocationPrivilegedRoles.includes(role) &&
      type !== singleLocationBusinessType);
  const locationName = getUserLocationName(user);

  const resolvedPage = getResolvedPage(pathName);

  const onOverviewTab = resolvedPage === Paths.dashboard;
  const onChatsTab =
    resolvedPage === Paths.chats ||
    resolvedPage === Paths.events ||
    resolvedPage === Paths.pipeline;
  const onTrainingTab =
    resolvedPage === Paths.training || resolvedPage === Paths.requests;
  const onContactsTab = resolvedPage === Paths.contacts;
  const onCampaignsTab = resolvedPage === Paths.campaigns;
  const onSequencesTab = resolvedPage === Paths.sequences;
  const onAgentsTab = resolvedPage === Paths.agents;
  const onAgentDemoTab = resolvedPage === Paths.agentDemo;
  const onUsersTab = resolvedPage === Paths.users;

  const BusinessTabs = inMonitoringMode ? (
    <TabContainer
      expanded={expanded}
      onClick={() => navigate(Paths.chats)}
    >
      {expanded ? (
        <>
          <ChatTabIcon selected={onChatsTab} />
          <TabText
            selected={onChatsTab}
            topMargin
          >
            Chats
          </TabText>
        </>
      ) : (
        <Tooltip
          title={<TooltipTitleText>Chats</TooltipTitleText>}
          placement='right'
        >
          <div>
            <ChatTabIcon selected={onChatsTab} />
          </div>
        </Tooltip>
      )}
    </TabContainer>
  ) : (
    <>
      <TabSection
        expanded={expanded}
        onClick={() => {
          if (!expanded) {
            navigate(Paths.dashboard);
          }
        }}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            unclickable
          >
            <HeadOfficeTabIcon
              selected={onOverviewTab}
              onClick={() => navigate(Paths.dashboard)}
              expanded={true}
            />
            <TabTextContainer>
              <TabText
                selected={onOverviewTab}
                onClick={() => navigate(Paths.dashboard)}
                leftMargin={-5}
              >
                Dashboard
              </TabText>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Dashboard</TooltipTitleText>}
            placement='right'
          >
            <div>
              <HeadOfficeTabIcon
                selected={onOverviewTab}
                onClick={() => navigate(Paths.dashboard)}
              />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => {
          if (!expanded) {
            navigate(Paths.chats);
          }
        }}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            unclickable
          >
            <ChatTabIcon
              selected={onChatsTab}
              expanded
            />
            <TabTextContainer>
              <TabText
                selected={onChatsTab}
                onClick={() => navigate(Paths.chats)}
              >
                Chats
              </TabText>
              <TabSubtext
                onClick={() => navigate(Paths.chats)}
                selected={pathName.includes(Paths.chats)}
              >
                Log
              </TabSubtext>
              <TabSubtext
                onClick={() => navigate(Paths.events)}
                selected={pathName.includes(Paths.events)}
              >
                Events
              </TabSubtext>
              <TabSubtext
                onClick={() => navigate(Paths.pipeline)}
                selected={pathName.includes(Paths.pipeline)}
              >
                Pipeline
              </TabSubtext>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Chats</TooltipTitleText>}
            placement='right'
          >
            <div>
              <ChatTabIcon
                selected={onChatsTab}
                onClick={() => navigate(Paths.chats)}
              />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => {
          if (!expanded) {
            navigate(Paths.training);
          }
        }}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            unclickable
          >
            <TrainingTabIcon selected={onTrainingTab} />
            <TabTextContainer>
              <TabText
                selected={onTrainingTab}
                onClick={() => navigate(Paths.training)}
              >
                TrainAI
              </TabText>
              <TabSubtext
                onClick={() => navigate(Paths.training)}
                selected={pathName.includes(Paths.training)}
              >
                Modules
              </TabSubtext>
              <TabSubtext
                onClick={() => navigate(Paths.requests)}
                selected={pathName.includes(Paths.requests)}
              >
                Feedback
              </TabSubtext>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>TrainAI</TooltipTitleText>}
            placement='right'
          >
            <div>
              <TrainingTabIcon selected={onTrainingTab} />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => {
          if (!expanded) {
            navigate(Paths.campaigns);
          }
        }}
      >
        {expanded ? (
          <>
            <TabContainer
              expanded={expanded}
              unclickable
            >
              <CampaignTabIcon selected={onCampaignsTab} />
              <TabTextContainer>
                <TabText
                  selected={onCampaignsTab}
                  onClick={() => navigate(Paths.campaigns)}
                >
                  Campaigns
                </TabText>
                <TabSubtext
                  onClick={() => navigate(Paths.campaigns)}
                  selected={pathName.includes(Paths.campaigns)}
                >
                  Campaigns
                </TabSubtext>
                <TabSubtext
                  onClick={() => navigate(Paths.audience)}
                  selected={pathName.includes(Paths.audience)}
                >
                  Audience
                </TabSubtext>
              </TabTextContainer>
            </TabContainer>
          </>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Campaigns</TooltipTitleText>}
            placement='right'
          >
            <div>
              <CampaignTabIcon selected={onCampaignsTab} />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => navigate(Paths.contacts)}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            onClick={() => navigate(Paths.contacts)}
          >
            <ClientsTabIcon selected={onContactsTab} />
            <TabTextContainer>
              <TabText selected={onContactsTab}>Contacts</TabText>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Contacts</TooltipTitleText>}
            placement='right'
          >
            <div>
              <ClientsTabIcon selected={onContactsTab} />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => navigate(Paths.agentDemo)}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            onClick={() => navigate(Paths.agentDemo)}
          >
            <DemoTabIcon selected={onAgentDemoTab} />
            <TabTextContainer>
              <TabText selected={onAgentDemoTab}>Demo</TabText>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Demo</TooltipTitleText>}
            placement='right'
          >
            <div>
              <DemoTabIcon selected={onAgentDemoTab} />
            </div>
          </Tooltip>
        )}
      </TabSection>
      <TabSection
        expanded={expanded}
        onClick={() => navigate(Paths.sequences)}
      >
        {expanded ? (
          <TabContainer
            expanded={expanded}
            onClick={() => navigate(Paths.sequences)}
          >
            <SequenceTabIcon selected={onSequencesTab} />
            <TabTextContainer>
              <TabText selected={onSequencesTab}>Sequences</TabText>
            </TabTextContainer>
          </TabContainer>
        ) : (
          <Tooltip
            title={<TooltipTitleText>Sequences</TooltipTitleText>}
            placement='right'
          >
            <div>
              <SequenceTabIcon selected={onSequencesTab} />
            </div>
          </Tooltip>
        )}
      </TabSection>
      {isSuperAdmin && (
        <>
          {!inDemoMode && (
            <TabSection
              expanded={expanded}
              onClick={() => navigate(Paths.users)}
            >
              {expanded ? (
                <TabContainer
                  expanded={expanded}
                  onClick={() => navigate(Paths.users)}
                >
                  <UsersTabIcon selected={onUsersTab} />
                  <TabTextContainer>
                    <TabText selected={onUsersTab}>Users</TabText>
                  </TabTextContainer>
                </TabContainer>
              ) : (
                <Tooltip
                  title={<TooltipTitleText>Users</TooltipTitleText>}
                  placement='right'
                >
                  <div>
                    <UsersTabIcon selected={onUsersTab} />
                  </div>
                </Tooltip>
              )}
            </TabSection>
          )}
        </>
      )}
    </>
  );

  return (
    open &&
    !isOnboardingPage && (
      <DrawerContainer expanded={expanded}>
        <ScrollWrapper>
          {isMultiLocationBusinessOwner && !inMonitoringMode ? (
            <>
              {locationName ? (
                <TabsContainer expanded={expanded}>
                  {BusinessTabs}
                </TabsContainer>
              ) : expanded ? (
                <LocationViewText>
                  Choose a location to <br></br>drill-down
                </LocationViewText>
              ) : (
                <></>
              )}
            </>
          ) : (
            <TabsContainer expanded={expanded}>{BusinessTabs}</TabsContainer>
          )}
        </ScrollWrapper>
        <BottomSection
          expanded={expanded}
          isMobileScreen={isMobileScreen}
          onClick={handleDrawerClick}
        >
          {expanded ? (
            <>
              <CollapseDrawerArrowIcon />
              <TabText noBottomMargin>Collapse</TabText>
            </>
          ) : (
            <Tooltip
              title={<TooltipTitleText>Expand Menu</TooltipTitleText>}
              placement='right'
            >
              <div>
                {expanded ? (
                  <CollapseDrawerArrowIcon />
                ) : (
                  <ExpandDrawerArrowIcon />
                )}
              </div>
            </Tooltip>
          )}
        </BottomSection>
      </DrawerContainer>
    )
  );
};

export default Drawer;
